import { Link } from 'react-router-dom';
import { IoArrowUndoSharp } from 'react-icons/io5';

import * as S from './style';

export function Rejected(): JSX.Element {
  return (
    <S.Page>
      <S.CardWrapper>
        <div className="card-info">
          <h1>Ops, acho que algo deu errado</h1>
          <p>
            No momento sua conta se encontra desativada em nossa plataforma, mas
            não se preocupe, você pode entrar em contato com o Administrador
            (+55 (19) 99928-3086 ou{' '}
            <a href="mailto: emaildocleber@gmail.com" className="card-email">
              emaildocleber@gmail.com
            </a>
            ) para pedir a reativação da conta!
          </p>
        </div>
        <div className="card-navigation">
          <Link to="/">
            <IoArrowUndoSharp /> Voltar para o Login
          </Link>
        </div>
      </S.CardWrapper>
    </S.Page>
  );
}
