import { useAuth } from '../../hooks/useAuth';
import { useProfileModal } from '../../hooks/useProfileModal';
import { EditProfileModal } from '../EditProfileModal';
import * as S from './style';

export function HeaderInfo(): JSX.Element {
  const { isProfileModalOpened, toggleProfileModalVisibility } =
    useProfileModal();
  const { user } = useAuth();

  return (
    <S.InfoContainer>
      <img
        src={user?.userAvatar ?? 'https://robohash.org/2B6.png?set=set3'}
        alt={user?.name ?? 'Avatar do usuário'}
      />
      <div className="user-info">
        <strong>{user?.name}</strong>
        <span>{user?.email}</span>
        {!user?.isAdmin && (
          <button type="button" onClick={toggleProfileModalVisibility}>
            Editar Perfil
          </button>
        )}
      </div>
      <EditProfileModal
        isEditingProfile={isProfileModalOpened}
        toggleModalVisibility={toggleProfileModalVisibility}
      />
    </S.InfoContainer>
  );
}
