import styled from 'styled-components';
import { BaseModal } from '../BaseModal';

export const ModalContainer = styled(BaseModal)``;

export const Content = styled.div`
  header {
    margin-bottom: 24px;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      color: #29292e;
      text-align: center;
    }
  }

  main {
    display: flex;

    img {
      margin-right: 32px;
      height: 200px;
      border-radius: 100px;
    }
  }
`;

export const Form = styled.form`
  > div {
    display: flex;
    gap: 8px;

    .input-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;

      label {
        font-size: 14px;
        margin-bottom: 4px;
      }

      input {
        width: 100%;
        height: 50px;
        border-radius: 8px;
        padding: 0 16px;
        background: #fff;
        border: 1px solid #a8a8b3;
      }

      .datepicker {
        width: 100%;
      }

      .input-error {
        text-align: left;
        color: #ea4335;
        font-size: 13px;
        margin-top: 4px;
      }

      &.large {
        flex: 2;
      }
    }

    & + div {
      margin-top: 8px;
    }
  }

  p {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;
    gap: 1rem;

    button {
      background-color: transparent;
      font-weight: bold;
      text-decoration: underline;
      color: #835afd;
      font-size: 1rem;
    }
  }

  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    gap: 8px;
  }
`;
