import styled from 'styled-components';

export const Header = styled.header`
  padding: 16px;
  border-bottom: 1px solid #e2e2e2;

  .content {
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > a {
      max-height: 30px;
      img {
        max-height: 30px;
      }
    }

    > nav {
      ul {
        list-style: none;
        display: flex;

        li {
          a {
            padding: 16px;
            border-radius: 8px;

            transition: background 0.3s, color 0.3s;

            &.active,
            &:hover {
              background: #835afd;
              color: #fff;
            }
          }

          & + li {
            margin-left: 8px;
          }
        }
      }
    }

    > div {
      display: flex;
      align-items: center;
      gap: 16px;

      svg {
        font-size: 24px;
        color: #835afd;
      }

      > button {
        height: 40px;
      }
    }
  }
`;
