import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import ReactModal from 'react-modal';

import ContextProvider from './contexts';

import Routes from './routes';

import GlobalStyles from './styles/global';
import { ModalWrapper } from './components/ModalWrapper';

ReactModal.setAppElement('#root');

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <ContextProvider>
        <ModalWrapper>
          <GlobalStyles />
          <Routes />
          <Toaster position="top-right" reverseOrder={false} />
        </ModalWrapper>
      </ContextProvider>
    </BrowserRouter>
  );
}

export default App;
