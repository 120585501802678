import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import toast from 'react-hot-toast';
import { ImSpinner } from 'react-icons/im';

import * as S from './style';
import { database, translateAuthErrors } from '../../services/firebase';
import { Button } from '../Button/style';
import { FileDropzone } from '../FileDropzone';
import { ClientType } from '../../hooks/useClients';
import { useAuth } from '../../hooks/useAuth';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

type FormValues = {
  name: string | null;
  email: string | null;
  avatar?: string | null;
  zip: string | null;
  street: string | null;
  number: number | string | null;
  district: string | null;
  complement: string | null;
  city: string | null;
  state: string | null;
  phone: string | null;
  whatsapp: string | null;
  contact: string | null;
};

type EditProfileModalProps = {
  editingClientId: string;
  toggleModalVisibility: () => void;
  clients: ClientType[];
  loading: boolean;
};

export function AdminEditProfileModal({
  editingClientId,
  toggleModalVisibility,
  clients,
  loading,
}: EditProfileModalProps): JSX.Element {
  const [formStep, setFormStep] = useState(1);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  const { sendForgotPasswordEmail } = useAuth();

  const formValidationSchema = Yup.object({
    name: Yup.string().min(8, 'O nome deve ter no mínimo 8 caracteres'),
    email: Yup.string().email('E-mail inválido'),
    zip: Yup.number().min(8, 'O nome deve ter no mínimo 8 caracteres'),
    street: Yup.string().min(6, 'O nome deve ter no mínimo 6 caracteres'),
    number: Yup.string(),
    district: Yup.string(),
    complement: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    phone: Yup.string().matches(phoneRegExp, 'Telefone inválido'),
    whatsapp: Yup.string().matches(phoneRegExp, 'Número de Whatsapp inválido'),
    contact: Yup.string().email('Email de contato inválido'),
  });

  const form = useFormik({
    initialValues: {
      name: '',
      email: '',
      zip: '',
      street: '',
      number: '',
      district: '',
      complement: '',
      city: '',
      state: '',
      phone: '',
      whatsapp: '',
      contact: '',
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSaveEditedClient, // eslint-disable-line
  });

  useEffect(() => {
    const clientToEdit = clients.find(client => client.id === editingClientId);

    setAvatarUrl(clientToEdit?.avatar ?? '');

    if (editingClientId && clientToEdit) {
      Object.entries(clientToEdit).forEach(([key, value]) => {
        form.setFieldValue(key, value);
      });
    }
  }, [editingClientId]); // eslint-disable-line

  function handleCloseModal() {
    toggleModalVisibility();
  }

  async function handleSaveEditedClient(values: FormValues) {
    if (loading) {
      toast.error('Aguarde o carregamento dos usuários', {
        icon: '⏳',
        position: 'bottom-center',
      });
      return;
    }

    try {
      const clientRef = await database.ref(`clients/${editingClientId}`);

      const updatedUser = {
        name: values.name,
        phone: values.phone,
        whatsapp: values.whatsapp,
        contact: values.contact,
        address: {
          zip: values.zip,
          district: values.district,
          street: values.street,
          number: values.number,
          complement: values.complement,
          city: values.city,
          state: values.state,
        },
      };

      await clientRef.update({
        ...updatedUser,
        updatedAt: Date.now(),
      });

      toast.success('Alteração salva com sucesso!', {
        position: 'bottom-center',
      });

      form.resetForm();
      toggleModalVisibility();
      setFormStep(1);
    } catch (error) {
      const { code } = error;
      const message = translateAuthErrors(code);
      toast.error(message);
    }
  }

  function goToNextStep() {
    setFormStep(2);
  }

  function goToPreviousStep() {
    setFormStep(1);
  }

  async function handleForgotPassword() {
    const clientInfo = clients.find(client => client.id === editingClientId);

    if (clientInfo?.email) await sendForgotPasswordEmail(clientInfo.email);
  }

  return (
    <S.ModalContainer
      isOpen={!!editingClientId}
      onRequestClose={() => handleCloseModal()}
    >
      <S.Content>
        <header>
          <h1>Editar Perfil</h1>
        </header>
        <main>
          <S.Form onSubmit={form.handleSubmit}>
            {formStep === 1 && (
              <>
                <div className="avatar-wrapper">
                  <FileDropzone
                    imagePreview={avatarUrl}
                    buttonDescription="Logo"
                    name="avatar"
                    setFieldValue={form.setFieldValue}
                  />
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editName">Nome</label>
                    <input
                      id="editName"
                      type="text"
                      placeholder="Nome"
                      {...form.getFieldProps('name')}
                    />
                    {form.touched.name && form.errors.name && (
                      <span className="input-error">{form.errors.name}</span>
                    )}
                  </div>
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editEmail">E-mail</label>
                    <input
                      id="editEmail"
                      type="text"
                      placeholder="E-mail"
                      {...form.getFieldProps('email')}
                    />
                    {form.touched.email && form.errors.email && (
                      <span className="input-error">{form.errors.email}</span>
                    )}
                  </div>
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editPhone">Telefone</label>
                    <input
                      id="editPhone"
                      type="text"
                      placeholder="(99) 9999-9999"
                      {...form.getFieldProps('phone')}
                    />
                    {form.touched.phone && form.errors.phone && (
                      <span className="input-error">{form.errors.phone}</span>
                    )}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="editWhatsapp">Whatsapp</label>
                    <input
                      id="editWhatsapp"
                      type="text"
                      placeholder="(99) 9999-9999"
                      {...form.getFieldProps('whatsapp')}
                    />
                    {form.touched.whatsapp && form.errors.whatsapp && (
                      <span className="input-error">
                        {form.errors.whatsapp}
                      </span>
                    )}
                  </div>
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editContact">E-mail de Contato</label>
                    <input
                      id="editContact"
                      type="email"
                      placeholder="Email de Contato"
                      {...form.getFieldProps('contact')}
                    />
                    {form.touched.contact && form.errors.contact && (
                      <span className="input-error">{form.errors.contact}</span>
                    )}
                  </div>
                </div>
                <p>
                  <button type="button" onClick={handleForgotPassword}>
                    Redefinir Senha
                  </button>
                </p>
              </>
            )}
            {formStep === 2 && (
              <>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editZip">CEP</label>
                    <input
                      id="editZip"
                      type="text"
                      placeholder="CEP"
                      {...form.getFieldProps('zip')}
                    />
                    {form.touched.zip && form.errors.zip && (
                      <span className="input-error">{form.errors.zip}</span>
                    )}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="editStreet">Logradouro</label>
                    <input
                      id="editStreet"
                      type="text"
                      placeholder="Rua"
                      {...form.getFieldProps('street')}
                    />
                    {form.touched.street && form.errors.street && (
                      <span className="input-error">{form.errors.street}</span>
                    )}
                  </div>
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editDistrict">Bairro</label>
                    <input
                      id="editDistrict"
                      type="text"
                      placeholder="Bairro"
                      {...form.getFieldProps('district')}
                    />
                    {form.touched.district && form.errors.district && (
                      <span className="input-error">
                        {form.errors.district}
                      </span>
                    )}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="editNumber">Número</label>
                    <input
                      id="editNumber"
                      type="text"
                      placeholder="Número"
                      {...form.getFieldProps('number')}
                    />
                    {form.touched.number && form.errors.number && (
                      <span className="input-error">{form.errors.number}</span>
                    )}
                  </div>
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editComplement">Complemento</label>
                    <input
                      id="editComplement"
                      type="text"
                      placeholder="Complemento"
                      {...form.getFieldProps('complement')}
                    />
                    {form.touched.complement && form.errors.complement && (
                      <span className="input-error">
                        {form.errors.complement}
                      </span>
                    )}
                  </div>
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editCity">Cidade</label>
                    <input
                      id="editCity"
                      type="text"
                      placeholder="Cidade"
                      {...form.getFieldProps('city')}
                    />
                    {form.touched.city && form.errors.city && (
                      <span className="input-error">{form.errors.city}</span>
                    )}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="editState">Estado</label>
                    <input
                      id="editState"
                      type="text"
                      placeholder="Estado"
                      {...form.getFieldProps('state')}
                    />
                    {form.touched.state && form.errors.state && (
                      <span className="input-error">{form.errors.state}</span>
                    )}
                  </div>
                </div>
              </>
            )}
            <footer>
              <Button
                type="button"
                isCancelButton
                onClick={() => handleCloseModal()}
              >
                Cancelar
              </Button>
              {formStep === 1 && (
                <Button type="button" onClick={goToNextStep}>
                  Avançar
                </Button>
              )}
              {formStep === 2 && (
                <>
                  <Button
                    type="button"
                    isCancelButton
                    onClick={goToPreviousStep}
                  >
                    Voltar
                  </Button>
                  <Button type="submit" disabled={form.isSubmitting}>
                    {!form.isSubmitting ? (
                      'Salvar'
                    ) : (
                      <ImSpinner className="icon-spin" />
                    )}
                  </Button>
                </>
              )}
            </footer>
          </S.Form>
        </main>
      </S.Content>
    </S.ModalContainer>
  );
}
