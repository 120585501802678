import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { ImSpinner } from 'react-icons/im';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { forwardRef } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useUsers } from '../../hooks/useUsers';
import { database, translateAuthErrors } from '../../services/firebase';
import { Button } from '../Button';

import { DatePickerField } from '../DatePicker';

import * as S from './style';

type FormValues = {
  subscriptionNumber: number | string;
  title: string;
  subscribedAt: Date | null;
  subscriptionValidity: Date | null;
  phone: string;
  name: string;
  birth: Date | null;
};

const titleOptions = [
  {
    label: 'Sócio',
    value: 'Sócio',
  },
  {
    label: 'Dependente',
    value: 'Dependente',
  },
];

export function NewUserForm(): JSX.Element {
  const { loading, users, setUsers } = useUsers();
  const { user } = useAuth();

  const formValidationSchema = Yup.object({
    subscriptionNumber: Yup.string().required('Preencha a matrícula'),
    title: Yup.string()
      .min(3, 'A titularidaede deve ter pelo menos 3 caracteres')
      .required('Preencha a titularidade'),
    subscribedAt: Yup.date()
      .nullable()
      .max(new Date(), 'A data de afiliação deve ser hoje ou anterior')
      .required('Preencha a data de afiliação'),
    subscriptionValidity: Yup.date()
      .nullable()
      .min(new Date(), 'A data de validade deve ser a partir de amanhã'),
    phone: Yup.string()
      .min(10, 'O Celular deve ter no mínimo 10 caracteres')
      .required('Preencha o Celular'),
    name: Yup.string()
      .min(8, 'O nome deve ter no mínimo 8 caracteres')
      .required('Preencha o nome do afiliado'),
    birth: Yup.date().nullable().required('Preencha a data de nascimento'),
  });

  const form = useFormik({
    initialValues: {
      subscriptionNumber: '',
      title: 'Sócio',
      subscribedAt: null,
      subscriptionValidity: null,
      phone: '',
      name: '',
      birth: null,
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleRegisterNewUser, // eslint-disable-line
  });

  async function handleRegisterNewUser(values: FormValues) {
    if (loading) {
      toast.error('Aguarde o carregamento dos usuários', {
        icon: '⏳',
        position: 'bottom-center',
      });
      return;
    }

    const parsedValues = {
      ...values,
      subscribedAt: Date.parse(String(values?.subscribedAt ?? '')),
      subscriptionValidity: values.subscriptionValidity
        ? Date.parse(String(values?.subscriptionValidity ?? ''))
        : null,
      birth: Date.parse(String(values?.birth ?? '')),
      termsAccepted: false,
      createdAt: Date.parse(String(new Date())),
    };

    try {
      if (users.some(hookUser => hookUser.phone === parsedValues.phone)) {
        toast.error('Existe outro usuário com esse telefone');
        return;
      }

      const clientRef = database.ref(`clients/${user?.id}/users`);

      const userRef = await clientRef.push(parsedValues);

      let unmaskedPhone = values.phone.replace('(', '');
      unmaskedPhone = unmaskedPhone.replace(')', '');
      unmaskedPhone = unmaskedPhone.replace(' ', '');
      unmaskedPhone = unmaskedPhone.replace('-', '');

      const phonesClients = database.ref(`phones_clients/${unmaskedPhone}`);

      await phonesClients.push({
        clientId: user?.id,
        userId: userRef.key,
      });

      const newUser = {
        ...values,
        id: userRef.key,
        subscriptionNumber: values.subscriptionNumber,
        termsAccepted: false,
        createdAt: new Date(),
      };

      // @ts-ignore
      setUsers([newUser, ...users]);

      toast.success('Usuário cadastrado com sucesso!', {
        position: 'bottom-center',
      });
      form.resetForm();
    } catch (error) {
      const { code } = error as { code: string };
      const message = translateAuthErrors(code);
      toast.error(message);
    }
  }

  const CustomMaskedInput = forwardRef(props => (
    <InputMask type="text" mask="99/99/9999" {...props} />
  ));

  return (
    <S.Form onSubmit={form.handleSubmit}>
      <div className="client-info">
        <div className="input-wrapper">
          <input
            id="subscriptionNumber"
            type="text"
            placeholder="Matrícula"
            {...form.getFieldProps('subscriptionNumber')}
          />
          {form.touched.subscriptionNumber &&
            form.errors.subscriptionNumber && (
              <span className="input-error">
                {form.errors.subscriptionNumber}
              </span>
            )}
        </div>
        <div className="input-wrapper">
          <select
            id="title"
            placeholder="Titularidade"
            {...form.getFieldProps('title')}
          >
            {titleOptions.map(titleOption => (
              <option
                value={titleOption.value}
                key={`${titleOption.value}-${titleOption.label}`}
              >
                {titleOption.label}
              </option>
            ))}
          </select>
          {form.touched.title && form.errors.title && (
            <span className="input-error">{form.errors.title}</span>
          )}
        </div>
        <div className="input-wrapper">
          <DatePickerField
            id="subscribedAt"
            className="datepicker"
            placeholderText="Data da Filiação"
            setFieldValue={form.setFieldValue}
            maxDate={new Date()}
            {...form.getFieldProps('subscribedAt')}
            customInput={<CustomMaskedInput />}
          />
          {form.touched.subscribedAt && form.errors.subscribedAt && (
            <span className="input-error">{form.errors.subscribedAt}</span>
          )}
        </div>
        <div className="input-wrapper">
          <DatePickerField
            id="subscriptionValidity"
            className="datepicker"
            placeholderText="Validade da Afiliação"
            setFieldValue={form.setFieldValue}
            minDate={new Date()}
            {...form.getFieldProps('subscriptionValidity')}
            customInput={<CustomMaskedInput />}
          />
          {form.touched.subscriptionValidity &&
            form.errors.subscriptionValidity && (
              <span className="input-error">
                {form.errors.subscriptionValidity}
              </span>
            )}
        </div>
      </div>
      <div className="user-info">
        <div className="input-wrapper">
          <InputMask
            id="phone"
            type="text"
            placeholder="Celular"
            mask="(99) 99999-9999"
            {...form.getFieldProps('phone')}
          />
          {form.touched.phone && form.errors.phone && (
            <span className="input-error">{form.errors.phone}</span>
          )}
        </div>
        <div className="input-wrapper large">
          <input
            id="name"
            type="text"
            placeholder="Nome Completo"
            {...form.getFieldProps('name')}
          />
          {form.touched.name && form.errors.name && (
            <span className="input-error">{form.errors.name}</span>
          )}
        </div>
        <div className="input-wrapper">
          <DatePickerField
            id="birth"
            className="datepicker"
            placeholderText="Data de Nascimento"
            showYearDropdown
            dropdownMode="select"
            setFieldValue={form.setFieldValue}
            {...form.getFieldProps('birth')}
            customInput={<CustomMaskedInput />}
          />
          {form.touched.birth && form.errors.birth && (
            <span className="input-error">{form.errors.birth}</span>
          )}
        </div>
      </div>

      <div className="form-footer">
        <Button type="submit" disabled={form.isSubmitting}>
          {!form.isSubmitting ? (
            'Cadastrar'
          ) : (
            <ImSpinner className="icon-spin" />
          )}
        </Button>
      </div>
    </S.Form>
  );
}
