import { useState } from 'react';

type UseModalReturnType = {
  isModalOpened: boolean;
  toggleModalVisibility: () => void;
};

export function useModal(): UseModalReturnType {
  const [isModalOpened, setIsModalOpened] = useState(false);

  function toggleModalVisibility() {
    setIsModalOpened(previousState => !previousState);
  }

  return { isModalOpened, toggleModalVisibility };
}
