import { useRouteMatch, Switch } from 'react-router';

import { SignIn } from '../pages/Admin/SignIn';
import { Dashboard } from '../pages/Admin/Dashboard';
import { CheckUsers } from '../pages/Admin/CheckUsers';

import CustomRoute from './Route';

export function AdminRoutes(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <CustomRoute exact path={path} component={SignIn} />
      <CustomRoute
        exact
        path={`${path}/dashboard`}
        component={Dashboard}
        isPrivate
        isAdmin
      />
      <CustomRoute
        exact
        path={`${path}/check-users`}
        component={CheckUsers}
        isPrivate
        isAdmin
      />
    </Switch>
  );
}
