import { createContext, ReactNode, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

import {
  firebase,
  auth,
  database,
  translateAuthErrors,
} from '../services/firebase';

enum AccountStatus { // eslint-disable-line
  REJECTED = 'rejected',
  PENDING = 'pending',
  APPROVED = 'approved',
}

type User = {
  name: string | null;
  id: string | null;
  email: string | null;
  userAvatar?: string | null;
  isAdmin?: boolean;
  address?: {
    zip: string;
    street: string;
    number: number | string;
    district: string;
    complement?: string;
    city: string;
    state: string;
  };
  phone?: string;
  whatsapp?: string;
  contact?: string;
  accountStatus?: 'pending' | 'approved' | 'rejected';
  settings?: {
    [key: string]: boolean;
  };
};

type UpdateUser = Partial<User>;

interface FirebaseUser extends User {
  accountStatus: 'pending' | 'approved' | 'rejected';
}

type AuthContextType = {
  user: User | undefined;
  loading: boolean;
  idToken: string | undefined;
  signInWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<void>;
  signUpWithEmailAndPassword: (
    name: string,
    email: string,
    password: string,
  ) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  updateUser: (userData: UpdateUser) => void;
  sendForgotPasswordEmail: (email: string) => void;
};

type AuthContextProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext({} as AuthContextType);

export function AuthContextProvider({
  children,
}: AuthContextProviderProps): JSX.Element {
  const [user, setUser] = useState<User | undefined>();
  const [loading, setLoading] = useState(true);
  const [idToken, setIdToken] = useState<string | undefined>();

  const history = useHistory();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
      if (firebaseUser) {
        const { email, uid } = firebaseUser;

        const userRef = await database.ref(`clients/${uid}`).get();
        const adminRef = await database.ref(`admin/${uid}`).get();

        if (userRef.exists()) {
          const userData: Omit<FirebaseUser, 'id' | 'email'> = userRef.val();

          if (userData.accountStatus === AccountStatus.APPROVED) {
            setUser({
              email,
              id: uid ?? null,
              name: userData.name,
              userAvatar: userData?.userAvatar || null,
              phone: userData.phone || '',
              whatsapp: userData.whatsapp || '',
              contact: userData.contact || '',
              address: {
                zip: userData.address?.zip || '',
                street: userData.address?.street || '',
                number: userData.address?.number || '',
                district: userData.address?.district || '',
                complement: userData.address?.complement || '',
                city: userData.address?.city || '',
                state: userData.address?.state || '',
              },
              settings: {
                ...userData?.settings,
              },
            });
          }
        } else if (adminRef.exists()) {
          const userData = adminRef.val();

          if (userData.accountStatus === AccountStatus.APPROVED) {
            setUser({
              email,
              id: uid,
              name: userData.name,
              userAvatar: userData.userAvatar,
              isAdmin: true,
            });

            history.push('/admin/dashboard');
          }
        }
      }

      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    const userRef = database.ref(`clients/${user?.id}`);

    userRef.on('child_changed', firebaseUser => {
      const { key } = firebaseUser;

      // @ts-ignore
      setUser(previousState => ({
        ...previousState,
        // @ts-ignore
        [key]: firebaseUser.val(),
      }));
    });

    return () => {
      userRef.off('child_changed');
    };
  }, [user?.id]);

  async function signInWithEmailAndPassword(email: string, password: string) {
    try {
      const result = await auth.signInWithEmailAndPassword(email, password);
      const userIdToken = await result.user?.getIdToken();
      setIdToken(userIdToken);

      if (result.user) {
        const { email: resultEmail, uid } = result.user;

        const userRef = await database.ref(`clients/${uid}`).get();

        if (userRef.exists()) {
          const userData = userRef.val();

          if (userData.accountStatus === AccountStatus.APPROVED) {
            setUser({
              email: resultEmail ?? null,
              id: uid ?? null,
              name: userData.name,
              userAvatar: userData?.userAvatar || null,
              phone: userData.phone || '',
              whatsapp: userData.whatsapp || '',
              contact: userData.contact || '',
              address: {
                zip: userData?.address?.zip || '',
                street: userData?.address?.street || '',
                number: userData?.address?.number || '',
                district: userData?.address?.district || '',
                complement: userData?.address?.complement || '',
                city: userData?.address?.city || '',
                state: userData?.address?.state || '',
              },
              settings: {
                ...userData?.settings,
              },
            });

            history.push('/dashboard');
          } else if (userData.accountStatus === AccountStatus.PENDING) {
            await auth.signOut();
            history.push('/aprovacao');
          } else if (userData.accountStatus === AccountStatus.REJECTED) {
            await auth.signOut();
            history.push('/reativar-conta');
          }
        }
      }
    } catch (error) {
      const { code } = error as { code: string };
      const message = translateAuthErrors(code);
      toast.error(message);
    }
  }

  async function signInWithGoogle() {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();

      const result = await auth.signInWithPopup(provider);

      if (result.user) {
        const { displayName, photoURL, uid, email } = result.user;

        const databaseRef = await database.ref(`admin/${uid}`);
        const databaseDataRef = await databaseRef.get();

        if (databaseDataRef.exists()) {
          const adminData = databaseDataRef.val();

          if (adminData.accountStatus === AccountStatus.APPROVED) {
            setUser({
              email: email ?? null,
              id: uid ?? null,
              name: displayName ?? null,
              userAvatar: photoURL ?? null,
              isAdmin: true,
            });

            history.push('/admin/dashboard');
          } else {
            history.push('/');
            throw new Error('auth/disapproved-account');
          }
        } else {
          if (!displayName || !photoURL) {
            throw new Error('auth/missing-information');
          }

          await databaseRef.set({
            name: displayName,
            approved: false,
            avatar: photoURL,
          });

          history.push('/');
          throw new Error('auth/pending-approval');
        }
      }
    } catch (error) {
      const { code, message } = error as { code: string; message: string };
      const translatedMesage = translateAuthErrors(code || message);
      toast.error(translatedMesage);
    }
  }

  async function signUpWithEmailAndPassword(
    name: string,
    email: string,
    password: string,
  ) {
    try {
      const result = await auth.createUserWithEmailAndPassword(email, password);

      if (result.user) {
        const { uid, metadata } = result.user;

        const createdAt = Date.parse(metadata?.creationTime ?? '');

        await database.ref(`clients/${uid}`).set({
          accountStatus: AccountStatus.PENDING,
          name,
          email,
          createdAt,
          updatedAt: createdAt,
        });

        await auth.signOut();

        setUser({
          name,
          id: uid,
          email,
          accountStatus: AccountStatus.PENDING,
        });

        history.push('/aprovacao');
      }
    } catch (error) {
      const { code } = error as { code: string };
      const message = translateAuthErrors(code);
      toast.error(message);
    }
  }

  async function signOut() {
    await auth.signOut();
    setUser(undefined);
  }

  function updateUser(userData: UpdateUser) {
    const updatedUser = { ...user, ...userData };

    setUser(updatedUser as User);
  }

  async function sendForgotPasswordEmail(email: string) {
    await auth.sendPasswordResetEmail(email);

    toast.success(`Foi enviado o link de redefinição de senha para ${email}`);
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        idToken,
        signInWithEmailAndPassword,
        signInWithGoogle,
        signUpWithEmailAndPassword,
        signOut,
        updateUser,
        sendForgotPasswordEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
