import { ReactNode } from 'react';

import * as S from './style';

type ClientProps = {
  name: string;
  email: string;
  children?: ReactNode;
  isHighlighted?: boolean;
  isInactive?: boolean;
};

export function Client({
  name,
  email,
  isHighlighted = false,
  isInactive = false,
  children = null,
}: ClientProps): JSX.Element {
  return (
    <S.Client isHighlighted={isHighlighted} isInactive={isInactive}>
      <div className="user-info">
        <strong>{name}</strong>
        <p>{email}</p>
      </div>
      <footer>
        <div>{children}</div>
      </footer>
    </S.Client>
  );
}
