import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

interface DatePickerFieldProps extends Omit<ReactDatePickerProps, 'value'> {
  name: string;
  value: string | null;
  setFieldValue: (field: string, value: Date | [Date, Date] | null) => void;
}

registerLocale('pt-BR', ptBR);

export const DatePickerField = ({
  name,
  value,
  setFieldValue,
  ...props
}: DatePickerFieldProps): JSX.Element => {
  return (
    <DatePicker
      locale="pt-BR"
      dateFormat="P"
      {...props}
      selected={(value && new Date(value)) || null}
      onChange={val => {
        setFieldValue(name, val);
      }}
    />
  );
};
