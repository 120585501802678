import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { ImSpinner } from 'react-icons/im';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/useAuth';
import { useBenefits } from '../../hooks/useBenefits';
import {
  database,
  storage,
  translateAuthErrors,
} from '../../services/firebase';
import { Button } from '../Button';
import { FileDropzone } from '../FileDropzone';

import * as S from './style';

type FormValues = {
  title: string;
  description: string;
  featuredImage: File | undefined;
};

export function NewBenefitForm(): JSX.Element {
  const { loading, benefits, setBenefits } = useBenefits();
  const { user } = useAuth();

  const formValidationSchema = Yup.object({
    title: Yup.string()
      .min(5, 'O título deve ter pelo menos 5 caracteres')
      .required('Preencha o título'),
    description: Yup.string().required('Preencha a descrição'),
    featuredImage: Yup.mixed()
      .test(
        'fileFormat',
        'O tipos de arquivos aceitos são: JPG, JPEG e PNG',
        value => {
          const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

          return value && SUPPORTED_FORMATS.includes(value.type);
        },
      )
      .test(
        'fileSize',
        'O tamanho máximo da imagem é 10Mb',
        value => value && value.size <= 10485760,
      ),
  });

  const form = useFormik({
    initialValues: {
      title: '',
      description: '',
      featuredImage: undefined,
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleRegisterNewBenefit, // eslint-disable-line
  });

  async function handleRegisterNewBenefit(values: FormValues) {
    if (loading) {
      toast.error('Aguarde o carregamento dos benefícios', {
        icon: '⏳',
        position: 'bottom-center',
      });
    }

    try {
      const { title, description, featuredImage } = values;

      const storageRef = storage
        .ref()
        .child(
          `clients/${user?.id}/benefits/${new Date().toISOString()}-${
            featuredImage?.name
          }`,
        );

      // @ts-ignore
      const uploadedFile = await storageRef.put(featuredImage);
      const downloadUrl = await uploadedFile.ref.getDownloadURL();

      const parsedValues = {
        title,
        description,
        featured_image: downloadUrl,
        createdAt: Date.parse(String(new Date())),
        updatedAt: Date.parse(String(new Date())),
      };

      const userRef = database.ref(`clients/${user?.id}/benefits`);

      const benefitRef = await userRef.push(parsedValues);

      const newBenefit = {
        id: benefitRef.key,
        ...parsedValues,
      };

      // @ts-ignore
      setBenefits([newBenefit, ...benefits]);

      toast.success('Benefício cadastrado com sucesso!', {
        position: 'bottom-center',
      });
      form.resetForm();
    } catch (error) {
      const { code } = error as { code: string };
      const message = translateAuthErrors(code);
      toast.error(message);
    }
  }

  return (
    <S.Form onSubmit={form.handleSubmit}>
      <div className="file-dropzone">
        <FileDropzone
          allowedFiles={['image/jpg', 'image/jpeg', 'image/png']}
          imageDimensions={{ width: 480, height: 270 }}
          buttonDescription="Imagem de Destaque"
          name="featuredImage"
          setFieldValue={form.setFieldValue}
          formValue={form.values.featuredImage}
        />
        {form.touched.featuredImage && form.errors.featuredImage && (
          <span className="input-error">{form.errors.featuredImage}</span>
        )}
      </div>
      <div className="input-wrapper">
        <input
          id="title"
          type="text"
          placeholder="Título"
          {...form.getFieldProps('title')}
        />
        {form.touched.title && form.errors.title && (
          <span className="input-error">{form.errors.title}</span>
        )}
      </div>
      <div className="input-wrapper">
        <textarea
          id="description"
          placeholder="Descrição do Benefício"
          rows={5}
          {...form.getFieldProps('description')}
        />
        {form.touched.description && form.errors.description && (
          <span className="input-error">{form.errors.description}</span>
        )}
      </div>

      <div className="form-footer">
        <Button type="submit" disabled={form.isSubmitting}>
          {!form.isSubmitting ? (
            'Cadastrar'
          ) : (
            <ImSpinner className="icon-spin" />
          )}
        </Button>
      </div>
    </S.Form>
  );
}
