import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 36px;
    color: #835afd;
  }
`;
