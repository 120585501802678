import { Link, useHistory } from 'react-router-dom';

import { ImSpinner } from 'react-icons/im';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';

import { useState } from 'react';
import { database } from '../../../services/firebase';

import { useAuth } from '../../../hooks/useAuth';
import { Button } from '../../../components/Button';
import { HeaderInfo } from '../../../components/HeaderInfo';

import * as S from './style';

import logoImg from '../../../assets/images/extended-logo.svg';
import { useAdminUsers } from '../../../hooks/useAdminUsers';
import { AdminUser } from '../../../components/AdminUser';

export function CheckUsers(): JSX.Element {
  const history = useHistory();
  const { user, signOut } = useAuth();
  const { users, loading: hookLoading, setUsers } = useAdminUsers();

  const [loading, setLoading] = useState(false);

  async function handleSignOut() {
    await signOut();

    history.push('/');
  }

  async function fixUser(id: string) {
    if (loading || hookLoading) return;

    const selectedUser = users.find(userFound => userFound[0] === id);

    const userRef = await database.ref(`phones_clients/${id}`);

    if (selectedUser) {
      setLoading(true);

      try {
        await userRef.update({
          clientId: null,
          userId: null,
        });

        await userRef.push({
          userId: selectedUser[1].userId,
          clientId: selectedUser[1].clientId,
        });

        setUsers(users.filter(filteredUser => filteredUser[0] !== id));
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <S.Page>
      <S.Header>
        <div className="content">
          <Link to="/dashboard">
            <img src={logoImg} alt="Socius" />
          </Link>
          <div>
            {user ? <HeaderInfo /> : <ImSpinner className="icon-spin" />}
            <Button type="button" isOutlined onClick={handleSignOut}>
              Sair
            </Button>
          </div>
        </div>
      </S.Header>
      <S.Main>
        <div className="title-container">
          <h1>Usuários com erro</h1>
          <Button
            type="button"
            isOutlined
            onClick={() => history.push('/admin/dashboard')}
          >
            Voltar
          </Button>
        </div>

        <div className="clients-list">
          {users.map(adminUser => (
            <AdminUser key={adminUser[0]} phone={adminUser[0]}>
              <Button type="button" onClick={() => fixUser(adminUser[0])}>
                {!loading ? (
                  <IoCheckmarkCircleOutline />
                ) : (
                  <ImSpinner className="icon-spin" />
                )}
              </Button>
            </AdminUser>
          ))}
        </div>
      </S.Main>
    </S.Page>
  );
}
