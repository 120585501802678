import { useEffect, useState } from 'react';
import { database } from '../services/firebase';

export type UserType = {
  [key: string]:
    | {
        clientId: string;
        userId: string;
      }
    | string;
};

type FirebaseUserData = {
  [key: string]:
    | {
        clientId: string;
        userId: string;
      }
    | string;
};

type FirebaseUsers = Record<string, FirebaseUserData>;

type UseClientsReturnType = {
  users: [string, FirebaseUserData][];
  setUsers: (user: [string, FirebaseUserData][]) => void;
  loading: boolean;
};

export function useAdminUsers(): UseClientsReturnType {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<[string, FirebaseUserData][]>([]);

  useEffect(() => {
    const clientsRef = database.ref('phones_clients');

    clientsRef.once('value', client => {
      const databaseUser: FirebaseUsers = client.val();

      if (!databaseUser) return;

      const filteredUsers = Object.entries(databaseUser).filter(([, value]) => {
        if (
          Object.keys(value).includes('clientId') &&
          Object.keys(value).includes('userId')
        )
          return true;

        return false;
      });

      setUsers(filteredUsers);
      setLoading(false);
    });

    return () => {
      clientsRef.off('value');
    };
  }, []);

  return { users, loading, setUsers };
}
