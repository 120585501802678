import { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useModal } from '../../hooks/useModal';
import { CompleteProfileModal } from '../CompleteProfileModal';

type ModalWrapperProps = {
  children: React.ReactNode;
};

export function ModalWrapper({ children }: ModalWrapperProps): JSX.Element {
  const { user, loading } = useAuth();
  const {
    isModalOpened,
    toggleModalVisibility: toggleCompleteProfileModalVisiblity,
  } = useModal();

  useEffect(() => {
    if (
      user &&
      (!user?.userAvatar ||
        !user.contact ||
        !user.phone ||
        !user.whatsapp ||
        !user.address?.city ||
        !user.address?.district ||
        !user.address?.number ||
        !user.address?.state ||
        !user.address?.street ||
        !user.address?.zip) &&
      !user?.isAdmin &&
      !loading
    ) {
      toggleCompleteProfileModalVisiblity();
    }
  }, [loading]); // eslint-disable-line

  return (
    <>
      {children}
      <CompleteProfileModal
        isModalOpened={isModalOpened}
        toggleModalVisibility={toggleCompleteProfileModalVisiblity}
      />
    </>
  );
}
