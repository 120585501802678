import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  align-self: stretch;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #f7f5ff;
`;

export const CardWrapper = styled.div`
  width: 880px;
  height: 440px;
  box-shadow: 0px 0px 100px 25px rgba(6, 0, 28, 0.2);
  position: relative;
  background-color: #fff;
  border-radius: 8px;

  .card-info {
    bottom: 40px;
    left: 40px;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #f7f5ff;
    border-radius: 8px;
    padding: 0 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      margin-bottom: 32px;
    }

    p {
      text-align: center;
    }

    .card-email {
      text-decoration: underline;
    }
  }

  .card-navigation {
    position: absolute;
    bottom: 0;
    left: 40px;
    height: 40px;
    display: flex;
    align-items: center;

    a {
      display: flex;
      padding: 4px 0;
      align-items: center;
      font-size: 16px;
      font-weight: 600;

      transition: color 0.3s;

      svg {
        margin-right: 8px;
      }

      &:hover {
        color: #835afd;
      }
    }
  }
`;
