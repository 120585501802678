import { useState } from 'react';

import { ImSpinner, ImSearch } from 'react-icons/im';
import { IoClose, IoSyncOutline, IoTrashOutline } from 'react-icons/io5';
import { FiEdit } from 'react-icons/fi';

import toast from 'react-hot-toast';

import { database } from '../../services/firebase';
import { useAuth } from '../../hooks/useAuth';
import { useUsers } from '../../hooks/useUsers';
import { UserType } from '../../contexts/UsersContext';

import { User } from '../../components/User';
import { Button } from '../../components/Button';
import { NewUserForm } from '../../components/NewUserForm';

import * as S from './style';

import { EditUserModal } from '../../components/EdituserModal';
import { Header } from '../../components/Header';

export function Dashboard(): JSX.Element {
  const [editingUserId, setEditingUserId] = useState('');

  const { user } = useAuth();
  const { users, setUsers, loading } = useUsers();

  const [searchInput, setSearchInput] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<UserType[]>([]);

  const userBadges = users.reduce(
    (
      accumulator: { activeUsers: number; inactiveUsers: number },
      value: UserType,
    ) => {
      return !value.deletedAt
        ? { ...accumulator, activeUsers: accumulator.activeUsers + 1 }
        : { ...accumulator, inactiveUsers: accumulator.inactiveUsers + 1 };
    },
    { activeUsers: 0, inactiveUsers: 0 },
  );

  async function restoreDeletedUser(userId: string) {
    if (loading) return;

    const clientRef = await database.ref(`clients/${user?.id}/users/${userId}`);

    await clientRef.update({
      deletedAt: null,
    });

    const updatedUsers = users.map(mapUser =>
      mapUser.id === userId
        ? {
            ...mapUser,
            deletedAt: null,
          }
        : mapUser,
    );

    setUsers(updatedUsers);

    toast.success('Usuário restaurado com sucesso', {
      position: 'bottom-center',
    });
  }

  async function deleteUser(userId: string) {
    if (loading) return;

    const clientRef = await database.ref(`clients/${user?.id}/users/${userId}`);

    await clientRef.update({
      deletedAt: Date.parse(String(new Date())),
    });

    const updatedUsers = users.map(mapUser =>
      mapUser.id === userId
        ? {
            ...mapUser,
            deletedAt: new Date(),
          }
        : mapUser,
    );

    setUsers(updatedUsers);

    toast.success('Usuário excluído com sucesso', {
      position: 'bottom-center',
    });
  }

  function toggleModalVisibility(userId?: string) {
    if (userId) setEditingUserId(userId);
    else setEditingUserId('');
  }

  function filterUsers() {
    const normalizedSearchInput = searchInput.trim().toLowerCase();

    const filteredUsersList = users.filter(userToFilter =>
      userToFilter.name.toLowerCase().includes(normalizedSearchInput),
    );

    setFilteredUsers(filteredUsersList);
  }

  function clearFilter() {
    setSearchInput('');
    setFilteredUsers([]);
  }

  function usersList(listUser: UserType) {
    return (
      <User key={listUser.id} data={listUser} isInactive={listUser.deletedAt}>
        {!listUser.deletedAt ? (
          <>
            <button
              type="button"
              className="edit-button"
              onClick={() => toggleModalVisibility(listUser.id)}
            >
              {!loading ? <FiEdit /> : <ImSpinner className="icon-spin" />}
            </button>
            <button
              type="button"
              className="delete-button"
              onClick={() => deleteUser(listUser.id)}
            >
              {!loading ? (
                <IoTrashOutline />
              ) : (
                <ImSpinner className="icon-spin" />
              )}
            </button>
          </>
        ) : (
          <button
            type="button"
            className="restore-button"
            onClick={() => restoreDeletedUser(listUser.id)}
          >
            {!loading ? <IoSyncOutline /> : <ImSpinner className="icon-spin" />}
          </button>
        )}
      </User>
    );
  }

  return (
    <S.Page>
      <Header />

      <S.Main>
        <div className="section-title">
          <h1>Cadastrar Novo Usuário</h1>
        </div>

        <NewUserForm />

        <div className="section-title">
          <h1>Lista de Usuários</h1>
          {userBadges.activeUsers > 0 && (
            <span>
              {userBadges.activeUsers} ativo{userBadges.activeUsers > 1 && 's'}
            </span>
          )}
          {userBadges.inactiveUsers > 0 && (
            <span className="inactive">
              {userBadges.inactiveUsers} inativo
              {userBadges.inactiveUsers > 1 && 's'}
            </span>
          )}
        </div>
        <div className="users-list">
          <S.SearchInputContainer>
            <input
              id="searchInput"
              type="text"
              placeholder="Pesquisar Usuários"
              value={searchInput}
              onChange={e => setSearchInput(e.target.value)}
            />
            {!!filteredUsers.length && (
              <Button type="button" isCancelButton onClick={clearFilter}>
                <IoClose />
              </Button>
            )}
            <Button type="button" onClick={filterUsers}>
              <ImSearch />
            </Button>
          </S.SearchInputContainer>
          {loading && <ImSpinner className="icon-spin" />}
          {!filteredUsers.length
            ? users.map(usersList)
            : filteredUsers.map(usersList)}
          <EditUserModal
            editingUserId={editingUserId}
            toggleModalVisibility={toggleModalVisibility}
          />
        </div>
      </S.Main>
    </S.Page>
  );
}
