import styled from 'styled-components';
import { BaseModal } from '../BaseModal';

export const ModalContainer = styled(BaseModal)``;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  header {
    margin-bottom: 24px;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      color: #29292e;
      text-align: center;
    }
  }

  main {
    p {
      color: #333333;
      margin: 0 0 24px;
      font: 400 1rem 'Roboto', sans-serif;
      line-height: 1.5rem;
    }

    .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: center;

      button:last-child {
        margin-left: 1rem;
      }
    }
  }
`;
