import styled, { css } from 'styled-components';

type ContainerProps = {
  hasImage: boolean;
  isDragActive: boolean;
  isDragAccept?: boolean;
  isDragReject?: boolean;
  imageDimensions?: {
    width: number;
    height: number;
  };
};

function getBorderColor(props: ContainerProps) {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return 'rgb(131, 90, 253)';
  }
  return 'rgba(115, 115, 128, 0.4)';
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  border-style: dashed;
  border-width: 2px;
  border-radius: 4px;
  border-color: ${props => getBorderColor(props)};

  outline: none;
  padding: 16px;

  transition: border 0.3s ease-in-out;

  img {
    object-fit: cover;
    width: ${({ imageDimensions }) =>
      imageDimensions ? `${imageDimensions.width}px` : '150px'};
    height: ${({ imageDimensions }) =>
      imageDimensions ? `${imageDimensions.height}px` : '150px'};
    border-radius: 8px;
    margin-bottom: 8px;

    ${({ hasImage }) =>
      !hasImage &&
      css`
        opacity: 0.5;
      `}
  }

  button {
    background-color: #dbdcdd;
    padding: 4px 8px;
    border-radius: 8px;
    color: #737380;

    transition: background 0.3s, color 0.3s;

    &:hover {
      background: #835afd;
      color: #fff;
    }
  }
`;
