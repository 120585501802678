import { Link } from 'react-router-dom';
import { IoArrowUndoSharp } from 'react-icons/io5';

import * as S from './style';

export function Approval(): JSX.Element {
  return (
    <S.Page>
      <S.CardWrapper>
        <div className="card-info">
          <h1>Estamos quase lá!</h1>
          <p>
            Para conseguir acessar a plataforma é preciso que um Administrador
            aprove sua conta, entre em contato para verificar o status da sua
            conta: +55 (19) 99928-3086 ou{' '}
            <a href="mailto: emaildocleber@gmail.com" className="card-email">
              emaildocleber@gmail.com
            </a>
          </p>
        </div>
        <div className="card-navigation">
          <Link to="/">
            <IoArrowUndoSharp /> Voltar para o Login
          </Link>
        </div>
      </S.CardWrapper>
    </S.Page>
  );
}
