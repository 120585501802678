import styled, { css } from 'styled-components';

type ClientProps = {
  isHighlighted?: boolean;
  isInactive?: boolean;
};

export const Client = styled.div<ClientProps>`
  background: ${({ isHighlighted, isInactive }) => {
    if (isInactive) return '#dbdcdd';

    if (isHighlighted && !isInactive) return '#f4f0ff';

    return '#fefefe';
  }};
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  padding: 24px;
  display: flex;

  &:not(:first-child) {
    margin-top: 8px;
  }

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      border: 1px solid #835afd;
    `}

  .user-info {
    display: flex;
    flex-direction: column;
    flex: 1;

    strong {
      font-weight: bold;
      font-size: 16px;
    }

    p {
      font-size: 14px;
      margin-top: 4px;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > div {
      display: flex;
      gap: 16px;
    }

    button {
      display: flex;
      border: 0;
      background: transparent;
      cursor: pointer;

      transition: filter 0.2s;

      svg {
        font-size: 24px;
        color: #737380;
      }

      &.approve-button:hover,
      &.edit-button:hover {
        svg {
          color: #835afd;
        }
      }

      &.reject-button:hover {
        svg {
          color: #ea4335;
        }
      }
    }
  }
`;
