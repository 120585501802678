import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ImSpinner } from 'react-icons/im';

import { useAuth } from '../../hooks/useAuth';

import { Button } from '../../components/Button';

import illustrationImg from '../../assets/images/illustration.svg';

import * as S from './style';
import { Loading } from '../Loading';

type FormValues = {
  email: string;
  password: string;
};

export function SignIn(): JSX.Element {
  const { loading, signInWithEmailAndPassword } = useAuth();

  const formValidationSchema = Yup.object({
    email: Yup.string().email('Email inválido').required('Preencha seu email'),
    password: Yup.string()
      .min(6, 'A senha deve ter pelo menos 6 caracteres')
      .required('Preencha sua senha'),
  });

  async function handleSignInToDashboard(values: FormValues) {
    const { email, password } = values;

    await signInWithEmailAndPassword(email, password);
  }

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSignInToDashboard,
  });

  return (
    <S.PageAuth>
      {loading ? (
        <Loading />
      ) : (
        <>
          <S.Aside>
            <img
              src={illustrationImg}
              alt="Ilustração simbolizando o cadastro de usuários"
            />
            <strong>Cadastre usuários de forma fácil e rápida!</strong>
            <p>Aqui vai algum outro texto que eu ainda não sei</p>
          </S.Aside>
          <S.Main>
            <div className="main-content">
              <form onSubmit={form.handleSubmit}>
                <input
                  id="email"
                  type="text"
                  placeholder="Seu email"
                  {...form.getFieldProps('email')}
                />
                {form.touched.email && form.errors.email && (
                  <span className="input-error">{form.errors.email}</span>
                )}
                <input
                  id="password"
                  type="password"
                  placeholder="Sua senha"
                  {...form.getFieldProps('password')}
                />
                {form.touched.password && form.errors.password && (
                  <span className="input-error">{form.errors.password}</span>
                )}
                <p>
                  <Link to="redefinir-senha">Esqueci minha senha</Link>
                </p>
                <Button type="submit" disabled={form.isSubmitting}>
                  {!form.isSubmitting ? (
                    'Entrar'
                  ) : (
                    <ImSpinner className="icon-spin" />
                  )}
                </Button>
              </form>
            </div>
            <footer className="content-footer">
              <p>
                Ainda não tem cadastro? <Link to="cadastro">Cadastre-se</Link>
              </p>
            </footer>
          </S.Main>
        </>
      )}
    </S.PageAuth>
  );
}
