import styled from 'styled-components';

export const Page = styled.div``;

export const Header = styled.header`
  padding: 16px;
  border-bottom: 1px solid #e2e2e2;

  .content {
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > a {
      max-height: 30px;
      img {
        max-height: 30px;
      }
    }

    > div {
      display: flex;
      align-items: center;
      gap: 16px;

      svg {
        font-size: 24px;
        color: #835afd;
      }

      button {
        height: 40px;
      }
    }
  }
`;

export const Main = styled.main`
  max-width: 800px;
  margin: 0 auto;

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 32px 0 24px;

    .list-title {
      display: flex;
      align-items: center;

      h1 {
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        color: #29292e;
      }

      span {
        margin-left: 16px;
        background: #e559f9;
        border-radius: 9999px;
        padding: 8px 16px;
        color: #fff;
        font-weight: 500;
        font-size: 14px;

        &.inactive {
          background: #c5c6c7;
        }

        &.pending {
          background: #835afd;
        }
      }
    }
  }

  .clients-list {
    margin-top: 32px;
    margin-bottom: 64px;
  }
`;
