import { createContext, ReactNode, useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { database } from '../services/firebase';

export type UserType = {
  id: string;
  subscriptionNumber: string | number;
  title: string;
  subscribedAt: Date;
  subscriptionValidity?: Date;
  phone: string;
  name: string;
  birth: Date;
  userAvatar?: string;
  createdAt: Date;
  deletedAt?: Date | null;
  expoPushToken: string | null;
};

type FirebaseUsers = Record<string, UserType>;

export type UsersContextType = {
  loading: boolean;
  users: UserType[];
  setUsers: (users: UserType[]) => void;
};

type UsersContextProviderProps = {
  children: ReactNode;
};

export const UsersContext = createContext({} as UsersContextType);

export function UsersContextProvider({
  children,
}: UsersContextProviderProps): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<UserType[]>([]);

  const { user } = useAuth();

  function sortUsers(unsortedUsers: UserType[]): UserType[] {
    const sortedClients = unsortedUsers.sort((a: UserType, b: UserType) => {
      if (a.name > b.name) return 1;
      if (b.name > a.name) return -1;

      return 0;
    });

    return sortedClients;
  }

  useEffect(() => {
    const clientsRef = database.ref(`clients/${user?.id}/users`);

    clientsRef.once('value', client => {
      const databaseUser: FirebaseUsers = client.val();

      if (!databaseUser) {
        setLoading(false);
        return;
      }

      const parsedClients = Object.entries(databaseUser).map(([key, value]) => {
        return {
          id: key,
          subscriptionNumber: String(value.subscriptionNumber),
          title: value.title,
          subscribedAt: value.subscribedAt,
          subscriptionValidity: value.subscriptionValidity,
          phone: value.phone,
          name: value.name,
          birth: value.birth,
          createdAt: value.createdAt,
          deletedAt: value.deletedAt,
          userAvatar: value.userAvatar,
          expoPushToken: value.expoPushToken,
        };
      });

      const sortedUsers = sortUsers([...parsedClients]);

      setUsers(sortedUsers);
      setLoading(false);
    });

    return () => {
      clientsRef.off('value');
    };
  }, [user?.id]);

  // useEffect(() => {
  //   const clientsRef = database.ref(`clients/${user?.id}/users`);

  //   clientsRef.on('child_added', firebaseUser => {
  //     console.log('Inicio do child_added');
  //     const parsedClient = {
  //       id: firebaseUser.key,
  //       ...firebaseUser.val(),
  //     };

  //     if (!users.find(stateUser => stateUser.id === firebaseUser.key)) {
  //       setUsers(previousState => [parsedClient, ...previousState]);
  //     }

  //     setLoading(false);
  //     console.log('Fim do child_added');
  //   });

  //   return () => {
  //     clientsRef.off('child_added');
  //   };
  // }, [users, user?.id]);

  // useEffect(() => {
  //   const clientsRef = database.ref(`clients/${user?.id}/users`);

  //   clientsRef.on('child_changed', firebaseUser => {
  //     console.log('Inicio do child_changed');
  //     const clientKey = firebaseUser.key;

  //     const updatedClient = {
  //       id: clientKey,
  //       ...firebaseUser.val(),
  //     };

  //     const updatedClients = users.map(stateUser =>
  //       stateUser.id === clientKey ? updatedClient : stateUser,
  //     );

  //     setUsers(updatedClients);
  //     console.log('Inicio do child_changed');
  //   });

  //   return () => {
  //     clientsRef.off('child_changed');
  //   };
  // }, [users, user?.id]);

  return (
    <UsersContext.Provider
      value={{
        loading,
        users,
        setUsers,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}
