import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ImSpinner } from 'react-icons/im';

import { useAuth } from '../../hooks/useAuth';

import { Button } from '../../components/Button';

import illustrationImg from '../../assets/images/illustration.svg';

import * as S from './style';
import { Loading } from '../Loading';

type FormValues = {
  email: string;
};

export function ForgotPassword(): JSX.Element {
  const { loading, sendForgotPasswordEmail } = useAuth();

  const history = useHistory();

  const formValidationSchema = Yup.object({
    email: Yup.string().email('Email inválido').required('Preencha seu email'),
  });

  async function handleSignInToDashboard(values: FormValues) {
    const { email } = values;

    await sendForgotPasswordEmail(email);

    history.push('/');
  }

  const form = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSignInToDashboard,
  });

  return (
    <S.PageAuth>
      {loading ? (
        <Loading />
      ) : (
        <>
          <S.Aside>
            <img
              src={illustrationImg}
              alt="Ilustração simbolizando o cadastro de usuários"
            />
            <strong>Redefinir Senha</strong>
          </S.Aside>
          <S.Main>
            <div className="main-content">
              <p>
                <Link to="/">Voltar para a Home</Link>
              </p>
              <form onSubmit={form.handleSubmit}>
                <input
                  id="email"
                  type="text"
                  placeholder="Seu email"
                  {...form.getFieldProps('email')}
                />
                {form.touched.email && form.errors.email && (
                  <span className="input-error">{form.errors.email}</span>
                )}
                <Button type="submit" disabled={form.isSubmitting}>
                  {!form.isSubmitting ? (
                    'Redefinir'
                  ) : (
                    <ImSpinner className="icon-spin" />
                  )}
                </Button>
              </form>
            </div>
          </S.Main>
        </>
      )}
    </S.PageAuth>
  );
}
