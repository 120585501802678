import { ReactNode } from 'react';
import ReactModal from 'react-modal';

type BaseModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  children?: ReactNode;
};

export function BaseModal({
  isOpen,
  onRequestClose,
  children,
}: BaseModalProps): JSX.Element {
  return (
    <ReactModal
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc={false}
    >
      {children}
    </ReactModal>
  );
}
