import styled from 'styled-components';

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 250px;

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 8px;

    strong {
      font-weight: bold;
      font-size: 16px;
    }

    span {
      font-size: 14px;
    }

    button {
      border: 0;
      background: transparent;
      font-size: 12px;
      padding: 4px 0;
      text-decoration: underline;

      transition: color 0.3s;

      &:hover {
        color: #835afd;
      }
    }
  }

  img {
    height: 60px;
    border-radius: 50%;
    background: #ddd;
  }
`;
