import styled from 'styled-components';

type ClientProps = {
  isHighlighted?: boolean;
  isInactive?: boolean;
};

export const Client = styled.div<ClientProps>`
  background: ${({ isInactive }) => (isInactive ? '#dbdcdd' : '#fefefe')};
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  padding: 24px;
  display: flex;
  align-items: center;

  img {
    height: 72px;
    margin-right: 16px;
  }

  &:not(:first-child) {
    margin-top: 8px;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    flex: 1;

    p {
      display: block;
      font-size: 14px;
      color: #777;
      margin-bottom: 4px;
      text-transform: uppercase;

      strong {
        text-transform: none;
        color: #29292e;
        font-weight: bold;
        font-size: 18px;
      }
    }

    span {
      font-size: 14px;
      color: #777;
      text-transform: uppercase;

      &:last-child {
        margin-top: 8px;
      }

      strong {
        color: #29292e;
        font-weight: bold;
      }
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      gap: 16px;

      button {
        display: flex;
        border: 0;
        background: transparent;
        cursor: pointer;

        transition: filter 0.2s;

        svg {
          font-size: 24px;
          color: #737380;
        }

        &.edit-button {
          svg {
            font-size: 22px;
          }
        }

        &.restore-button {
          svg {
            font-size: 26px;
          }
        }

        &.edit-button:hover,
        &.restore-button:hover {
          svg {
            color: #835afd;
          }
        }

        &.delete-button:hover {
          svg {
            color: #ea4335;
          }
        }
      }
    }
  }
`;
