import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { ImSpinner } from 'react-icons/im';
import * as Yup from 'yup';

import { Button } from '../Button/style';
import { useAuth } from '../../hooks/useAuth';
import {
  database,
  storage,
  translateAuthErrors,
} from '../../services/firebase';

import * as S from './style';
import { useBenefits } from '../../hooks/useBenefits';
import { FileDropzone } from '../FileDropzone';

type FormValues = {
  title: string;
  description: string;
  featuredImage: File | undefined;
  featuredImageUrl: string | null;
};

type EditBenefitsModal = {
  editingBenefitsId: string;
  toggleModalVisibility: (newsId?: string) => void;
};

export function EditBenefitsModal({
  editingBenefitsId,
  toggleModalVisibility,
}: EditBenefitsModal): JSX.Element {
  const { user } = useAuth();
  const { loading, benefits, setBenefits } = useBenefits();

  const [uploadLoading, setUploadLoading] = useState(false);

  const formValidationSchema = Yup.object({
    title: Yup.string()
      .min(5, 'O título deve ter pelo menos 5 caracteres')
      .required('Preencha o título'),
    description: Yup.string().required('Preencha a descrição'),
    faturedImageUrl: Yup.string().nullable(true),
    featuredImage: Yup.mixed()
      .test(
        'fileFormat',
        'O tipos de arquivos aceitos são: JPG, JPEG e PNG',
        value => {
          if (!value || typeof value === 'string') return true;
          const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

          return value && SUPPORTED_FORMATS.includes(value.type);
        },
      )
      .test('fileSize', 'O tamanho máximo da imagem é 10Mb', value => {
        if (!value || typeof value === 'string') return true;
        return value && value.size <= 10485760;
      }),
  });

  const form = useFormik({
    initialValues: {
      title: '',
      description: '',
      featuredImage: undefined,
      featuredImageUrl: null,
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSaveEditedBenefits, // eslint-disable-line
  });

  useEffect(() => {
    if (editingBenefitsId) {
      const benefitsToEditData = benefits.find(
        findBenefits => findBenefits.id === editingBenefitsId,
      );

      if (benefitsToEditData) {
        Object.entries(benefitsToEditData).forEach(([key, value]) => {
          if (key === 'featured_image') {
            form.setFieldValue('featuredImageUrl', value);
          } else {
            form.setFieldValue(key, value);
          }
        });
      }
    }
  }, [editingBenefitsId]); // eslint-disable-line

  function handleCloseModal() {
    toggleModalVisibility();
    form.resetForm();
  }

  async function uploadBenefitsFeaturedImage(featuredImage: File | unknown) {
    try {
      if (!featuredImage || !(featuredImage instanceof File)) {
        return null;
      }

      setUploadLoading(true);

      const storageRef = storage
        .ref()
        .child(
          `clients/${user?.id}/benefits/${new Date().toISOString()}-${
            featuredImage?.name
          }`,
        );

      // @ts-ignore
      const uploadedFile = await storageRef.put(featuredImage);
      const downloadUrl = await uploadedFile.ref.getDownloadURL();

      setUploadLoading(false);
      return downloadUrl;
    } catch (e) {
      toast.error('Erro ao salvar a imagem do benefício', {
        position: 'bottom-center',
      });
      setUploadLoading(false);
    }
  }

  async function handleSaveEditedBenefits(values: FormValues) {
    if (loading) {
      toast.error('Aguarde o carregamento dos benefícios', {
        icon: '⏳',
        position: 'bottom-center',
      });
      return;
    }

    if (uploadLoading) {
      toast.error('Aguarde o upload da imagem', {
        icon: '⏳',
        position: 'bottom-center',
      });
      return;
    }

    try {
      const { featuredImage, featuredImageUrl, title, description } = values;

      const featuredImageDownloadUrl = await uploadBenefitsFeaturedImage(
        featuredImage,
      );

      const benefitsRef = database.ref(
        `clients/${user?.id}/benefits/${editingBenefitsId}`,
      );

      const updatedBenefit = {
        title,
        description,
        featured_image: featuredImageDownloadUrl || featuredImageUrl,
      };

      await benefitsRef.update({
        ...updatedBenefit,
        updatedAt: Date.now(),
      });

      const updatedBenefits = benefits.map(mapBenefit =>
        mapBenefit.id === editingBenefitsId
          ? {
              ...mapBenefit,
              ...updatedBenefit,
              updatedAt: Date.now(),
            }
          : mapBenefit,
      );

      setBenefits(updatedBenefits);

      toast.success('Alteração salva com sucesso!', {
        position: 'bottom-center',
      });

      toggleModalVisibility();
    } catch (error) {
      const { code } = error as { code: string };
      const message = translateAuthErrors(code);
      toast.error(message);
    }
  }

  return (
    <S.ModalContainer
      isOpen={!!editingBenefitsId}
      onRequestClose={() => handleCloseModal()}
    >
      <S.Content>
        <header>
          <h1>Edição de Benefício</h1>
        </header>
        <main>
          <S.Form onSubmit={form.handleSubmit}>
            <div className="benefits-info">
              <div className="image-container">
                <FileDropzone
                  imagePreview={form.values.featuredImageUrl}
                  imageDimensions={{ width: 240, height: 135 }}
                  dimensionsToShow={{ width: 1920, height: 1080 }}
                  allowedFiles={['image/jpg', 'image/jpeg', 'image/png']}
                  buttonDescription="Imagem de Destaque"
                  name="featuredImage"
                  setFieldValue={form.setFieldValue}
                  formValue={form.values.featuredImage}
                />
              </div>
              <div className="input-wrapper">
                <input
                  id="title"
                  type="text"
                  placeholder="Título"
                  {...form.getFieldProps('title')}
                />
                {form.touched.title && form.errors.title && (
                  <span className="input-error">{form.errors.title}</span>
                )}
              </div>
              <div className="input-wrapper">
                <textarea
                  id="description"
                  placeholder="Descrição do Benefício"
                  rows={5}
                  {...form.getFieldProps('description')}
                />
                {form.touched.description && form.errors.description && (
                  <span className="input-error">{form.errors.description}</span>
                )}
              </div>
            </div>
            <footer>
              <Button
                type="button"
                isCancelButton
                onClick={() => handleCloseModal()}
              >
                Cancelar
              </Button>
              <Button type="submit" disabled={form.isSubmitting}>
                {!form.isSubmitting ? (
                  'Salvar'
                ) : (
                  <ImSpinner className="icon-spin" />
                )}
              </Button>
            </footer>
          </S.Form>
        </main>
      </S.Content>
    </S.ModalContainer>
  );
}
