import { ReactNode } from 'react';
import { formatDate } from '../../utils/formatDate';

import * as S from './style';

type ClientProps = {
  data: {
    name: string;
    title: string;
    subscribedAt: Date;
    subscriptionNumber: string | number;
    userAvatar?: string;
  };
  children?: ReactNode;
  isInactive?: Date | null;
};

export function User({
  data,
  isInactive = null,
  children = null,
}: ClientProps): JSX.Element {
  return (
    <S.Client isInactive={!!isInactive}>
      {data.userAvatar && (
        <img src={data.userAvatar} alt={`Avatar de ${data.name}`} />
      )}
      <div className="user-info">
        <p>
          <strong>{data.name}</strong> - {data.title}
        </p>
        <span>
          Matrícula: <strong>{data.subscriptionNumber}</strong> / Afiliação:{' '}
          <strong>{formatDate(data.subscribedAt)}</strong>
        </span>
        {isInactive && (
          <span>
            Excluído em: <strong>{formatDate(isInactive)}</strong>
          </span>
        )}
      </div>
      <footer>
        <div>{children}</div>
      </footer>
    </S.Client>
  );
}
