import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';

import * as S from './style';

interface File extends Blob {
  lastModified: number;
  name: string;
}

type FileDropzoneProps = {
  imagePreview?: string | null | undefined;
  allowedFiles?: string[];
  imageDimensions?: {
    width: number;
    height: number;
  };
  dimensionsToShow?: {
    width: number;
    height: number;
  };
  buttonDescription?: string;
  name: string;
  setFieldValue: (name: string, file: File | null) => void;
  formValue?: File | undefined;
};

export function FileDropzone({
  imagePreview,
  allowedFiles,
  imageDimensions,
  dimensionsToShow,
  buttonDescription,
  name,
  setFieldValue,
  formValue,
}: FileDropzoneProps): JSX.Element {
  const [image] = useState<File | null>(null);
  const [preview, setPreview] = useState(() => imagePreview || '');

  useEffect(() => {
    if (!formValue && !imagePreview) setPreview('');
  }, [formValue, imagePreview]);

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: allowedFiles ? allowedFiles.join(',') : 'image/*',
    maxFiles: 1,
    onDrop: acceptedFiles => {
      if (acceptedFiles.length) {
        setFieldValue(name, acceptedFiles[0]);
        setPreview(URL.createObjectURL(acceptedFiles[0]));
      } else {
        toast.error('Tipo de arquivo inválido', {
          icon: '🚫',
          position: 'bottom-center',
        });
      }
    },
  });

  return (
    <S.Container
      hasImage={!!image || !!preview}
      imageDimensions={imageDimensions}
      {...getRootProps({ isDragActive, isDragReject, isDragAccept })}
    >
      <input {...getInputProps()} />
      <img
        src={
          preview ||
          `https://via.placeholder.com/${
            dimensionsToShow
              ? dimensionsToShow?.width
              : (imageDimensions?.width || 150) * 4
          }x${
            dimensionsToShow
              ? dimensionsToShow?.height
              : (imageDimensions?.height || 150) * 4
          }`
        }
        alt={
          preview
            ? 'Logo do usuário'
            : 'Imagem de placeholder para usuários que não tem um avatar'
        }
      />
      <button type="button" onClick={open}>
        Selecionar {buttonDescription || 'Arquivo'}
      </button>
    </S.Container>
  );
}
