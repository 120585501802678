import { ReactNode } from 'react';
import * as S from './style';

type ClientProps = {
  phone: string;
  children: ReactNode;
};

export function AdminUser({ phone, children }: ClientProps): JSX.Element {
  function maskPhone(rawPhone: string) {
    return rawPhone
      .replace(/\D/g, '')
      .replace(/^(\d)/, '($1')
      .replace(/^(\(\d{2})(\d)/, '$1) $2')
      .replace(/(\d{5})(\d{1,5})/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  }

  return (
    <S.User>
      <div className="user-info">
        <strong>Usuário: </strong>
        <p>{maskPhone(phone)}</p>
      </div>
      <footer>
        <div>{children}</div>
      </footer>
    </S.User>
  );
}
