import styled from 'styled-components';

export const Form = styled.form`
  > div {
    display: flex;
    gap: 8px;

    .input-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;

      input,
      select {
        width: 100%;
        height: 50px;
        border-radius: 8px;
        padding: 0 16px;
        background: #fff;
        border: 1px solid #a8a8b3;
      }

      select {
        color: #75758b;
      }

      .datepicker {
        width: 100%;
      }

      .input-error {
        text-align: left;
        color: #ea4335;
        font-size: 13px;
        margin-top: 4px;
      }

      &.large {
        flex: 2;
      }
    }

    & + div {
      margin-top: 8px;
    }
  }

  .form-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
  }
`;
