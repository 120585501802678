import { BenefitType } from '../contexts/BenefitsContext';
import { NewsType } from '../contexts/NewsContext';
import { api } from './api';

export enum NotificationRefs {
  BENEFIT = 'benefício',
  NEWS = 'notícia',
}

export enum NotificationTypes {
  NEW = 'new',
  UPDATE = 'update',
}

type SendNotificationConfig = {
  ref: NotificationRefs;
  type: NotificationTypes;
};

type User = {
  name: string | null;
  idToken: string | undefined;
};

export type SendNotificationProps = {
  config: SendNotificationConfig;
  data: BenefitType | NewsType;
  recipients: string[];
  user: User;
};

function getNotificationTitle(config: SendNotificationConfig) {
  if (config.type === NotificationTypes.NEW) {
    return `${config.ref === NotificationRefs.BENEFIT ? 'Novo' : 'Nova'} ${
      config.ref
    } cadastrad${config.ref === NotificationRefs.BENEFIT ? 'o' : 'a'}`;
  }

  return `${config.ref === NotificationRefs.BENEFIT ? 'Um' : 'Uma'} ${
    config.ref
  } foi atualizad${config.ref === NotificationRefs.BENEFIT ? 'o' : 'a'}`;
}

function getNotificationBody(
  config: SendNotificationConfig,
  data: BenefitType | NewsType,
  userName: string | null,
) {
  if (config.type === NotificationTypes.NEW) {
    return `Venha conferir ${
      config.ref === NotificationRefs.BENEFIT ? 'o' : 'a'
    } ${config.ref} "${data.title}" em ${userName}`;
  }

  return `${config.ref === NotificationRefs.BENEFIT ? 'O' : 'A'} ${
    config.ref
  } "${data.title}" foi atualizad${
    config.ref === NotificationRefs.BENEFIT ? 'o' : 'a'
  } em ${userName}`;
}

export async function sendNotification({
  config,
  data,
  recipients,
  user,
}: SendNotificationProps): Promise<void> {
  const notificationBody = {
    to: recipients,
    title: getNotificationTitle(config),
    body: getNotificationBody(config, data, user.name),
  };

  try {
    if (!user.idToken)
      throw new Error(
        'Não foi possível recuperar o token de autenticação do usuário',
      );

    const { data: responseData } = await api.post(
      '/send-notifications',
      notificationBody,
      {
        headers: {
          Authorization: `Bearer ${user.idToken}`,
        },
      },
    );

    return responseData;
  } catch (err) {
    throw new Error();
  }
}
