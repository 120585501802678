import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

interface Props extends RouteProps {
  isPrivate?: boolean;
  isAdmin?: boolean;
}

export default function CustomRoute({
  isPrivate = false,
  isAdmin = false,
  ...rest
}: Props): JSX.Element | null {
  const { user, loading } = useAuth();

  if (isPrivate && !user && !loading) {
    return <Redirect to="/" />;
  }

  if (isAdmin && isPrivate && !user && !loading) {
    return <Redirect to="/" />;
  }

  if (
    user &&
    user?.isAdmin &&
    !isPrivate &&
    !loading &&
    rest.location?.pathname !== '/admin/dashboard' &&
    rest.location?.pathname !== '/admin/check-users'
  ) {
    return <Redirect to="/admin/dashboard" />;
  }

  if (
    user &&
    !user?.isAdmin &&
    !isPrivate &&
    !loading &&
    user.accountStatus === 'approved' &&
    !rest.location?.pathname.includes('/dashboard')
  ) {
    return <Redirect to="/dashboard" />;
  }

  return <Route {...rest} />;
}
