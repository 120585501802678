import styled, { css } from 'styled-components';

type ButtonProps = {
  isOutlined?: boolean;
  isCancelButton?: boolean;
  buttonColor?: string;
};

export const Button = styled.button<ButtonProps>`
  height: 50px;
  border-radius: 8px;
  font-weight: 500;
  background: ${({ isOutlined, isCancelButton, buttonColor }) => {
    if (isOutlined && !isCancelButton) return '#fff';
    if (isCancelButton) return '#DBDCDD';
    if (buttonColor) return buttonColor;
    return '#835afd';
  }};
  color: ${({ isOutlined, isCancelButton, buttonColor }) => {
    if (isOutlined && !isCancelButton) {
      if (buttonColor) return buttonColor;
      return '#835afd';
    }
    if (isCancelButton) return '#737380';
    return '#fff';
  }};
  padding: 0 32px;

  ${({ isOutlined, buttonColor }) =>
    isOutlined &&
    css`
      border: 1px solid ${buttonColor || '#835afd'};
    `};

  display: flex;
  justify-content: center;
  align-items: center;

  transition: background 0.3s, color 0.3s, filter 0.2s;

  img {
    margin-right: 8px;
  }

  &:not(:disabled):hover {
    ${({ isOutlined, buttonColor }) =>
      isOutlined
        ? css`
            background: ${buttonColor || '#835afd'};
            color: #fff;
          `
        : css`
            filter: brightness(0.9);
          `}
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
