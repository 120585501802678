import { Switch } from 'react-router-dom';

import { SignIn } from '../pages/SignIn';
import { SignUp } from '../pages/SignUp';
import { Approval } from '../pages/Approval';
import { Rejected } from '../pages/Rejected';
import { Dashboard } from '../pages/Dashboard';
import { Noticias } from '../pages/Noticias';
import { Beneficios } from '../pages/Beneficios';
import { Settings } from '../pages/Settings';

import { AdminRoutes } from './admin';

import CustomRoute from './Route';
import { ForgotPassword } from '../pages/ForgotPassword';

export default function Routes(): JSX.Element {
  return (
    <Switch>
      <CustomRoute path="/" component={SignIn} exact />
      <CustomRoute path="/redefinir-senha" component={ForgotPassword} exact />
      <CustomRoute path="/cadastro" component={SignUp} exact />
      <CustomRoute path="/aprovacao" component={Approval} exact />
      <CustomRoute path="/reativar-conta" component={Rejected} exact />
      <CustomRoute path="/dashboard" component={Dashboard} isPrivate exact />
      <CustomRoute
        path="/dashboard/configuracoes"
        component={Settings}
        isPrivate
        exact
      />
      <CustomRoute
        path="/dashboard/noticias"
        component={Noticias}
        isPrivate
        exact
      />
      <CustomRoute
        path="/dashboard/beneficios"
        component={Beneficios}
        isPrivate
        exact
      />
      <CustomRoute path="/admin" component={AdminRoutes} />
    </Switch>
  );
}
