import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

type User = {
  name: string | null;
  id: string | null;
  email: string | null;
  userAvatar?: string | null;
  isAdmin?: boolean;
  address?: {
    zip: string;
    street: string;
    number: number | string;
    district: string;
    complement?: string;
    city: string;
    state: string;
  };
  phone?: string;
  whatsapp?: string;
  contact?: string;
  accountStatus?: 'pending' | 'approved' | 'rejected';
  settings?: {
    [key: string]: boolean;
  };
};

type UpdateUser = Omit<User, 'id' | 'email'>;

type UseAuthReturnType = {
  user: User | undefined;
  loading: boolean;
  idToken: string | undefined;
  signInWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<void>;
  signUpWithEmailAndPassword: (
    email: string,
    name: string,
    password: string,
  ) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  updateUser: (userData: UpdateUser) => void;
  sendForgotPasswordEmail: (email: string) => void;
};

export function useAuth(): UseAuthReturnType {
  const value = useContext(AuthContext);

  return value;
}
