import { createContext, ReactNode, useState } from 'react';

type ProfileContextType = {
  isProfileModalOpened: boolean;
  toggleProfileModalVisibility: () => void;
};

type ProfileContextProviderProps = {
  children: ReactNode;
};

export const ProfileContext = createContext({} as ProfileContextType);

export function ProfileContextProvider({
  children,
}: ProfileContextProviderProps): JSX.Element {
  const [isModalOpened, setIsModalOpened] = useState(false);

  function toggleProfileModalVisibility() {
    setIsModalOpened(previousState => !previousState);
  }

  return (
    <ProfileContext.Provider
      value={{
        isProfileModalOpened: isModalOpened,
        toggleProfileModalVisibility,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}
