import { ImSpinner } from 'react-icons/im';
import { FiEdit, FiRadio } from 'react-icons/fi';
import { IoTrashOutline } from 'react-icons/io5';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { isAfter, sub } from 'date-fns';
import { useAuth } from '../../hooks/useAuth';

import * as S from './style';

import { NewNewsForm } from '../../components/NewNewsForm';
import { News } from '../../components/News';
import { useNews } from '../../hooks/useNews';
import { database } from '../../services/firebase';

import { EditNewsModal } from '../../components/EditNewsModal';
import { NewsType } from '../../contexts/NewsContext';
import {
  NotificationRefs,
  NotificationTypes,
  sendNotification,
  SendNotificationProps,
} from '../../services/notification';
import { useUsers } from '../../hooks/useUsers';
import { UserType } from '../../contexts/UsersContext';
import { Header } from '../../components/Header';

export function Noticias(): JSX.Element {
  const [editingNewsId, setEditingNewsId] = useState('');

  const { user, idToken } = useAuth();
  const { news, setNews, loading, setLoading } = useNews();
  const { users } = useUsers();

  const newsBadges = news.reduce(
    (accumulator: { activeNews: number; inactiveNews: number }) => {
      return { ...accumulator, activeNews: accumulator.activeNews + 1 };
    },
    { activeNews: 0, inactiveNews: 0 },
  );

  function toggleModalVisibility(newsId?: string) {
    if (newsId) setEditingNewsId(newsId);
    else setEditingNewsId('');
  }

  async function deleteNews(newsId: string) {
    if (loading) return;

    const newsRef = await database.ref(`clients/${user?.id}/news/${newsId}`);

    await newsRef.remove();

    const updatedNews = news.filter(mapNews => mapNews.id !== newsId);

    setNews(updatedNews);

    toast.success('Notícia excluída com sucesso', {
      position: 'bottom-center',
    });
  }

  async function handleSendNotification(sendingNews: NewsType) {
    setLoading(true);
    try {
      const { notificationSentAt } = sendingNews;

      if (notificationSentAt) {
        const formattedNotificationSentAt = new Date(notificationSentAt);
        const twoDaysAgo = sub(new Date(), { hours: 48 });

        if (isAfter(formattedNotificationSentAt, twoDaysAgo)) {
          setLoading(false);
          toast.error(
            'É permitido uma notificação por notícia a cada 48 horas',
          );
          return;
        }
      }

      const recipients = users.reduce(
        (reduceRecipients: string[], reduceUser: UserType) => {
          if (reduceUser.expoPushToken)
            return [...reduceRecipients, reduceUser.expoPushToken];
          return reduceRecipients;
        },
        [],
      );

      const notification: SendNotificationProps = {
        config: {
          ref: NotificationRefs.NEWS,
          type:
            sendingNews?.updatedAt !== sendingNews.createdAt
              ? NotificationTypes.UPDATE
              : NotificationTypes.NEW,
        },
        data: sendingNews,
        recipients,
        user: {
          name: user?.name || null,
          idToken,
        },
      };

      await sendNotification(notification);
      const updatedSentTime = Date.now();

      const newsRef = database.ref(
        `clients/${user?.id}/news/${sendingNews.id}`,
      );
      await newsRef.update({
        notificationSentAt: updatedSentTime,
      });
      const updatedNews = news.map(mapNews => {
        if (mapNews.id === sendingNews.id)
          return {
            ...mapNews,
            notificationSentAt: updatedSentTime,
          };

        return mapNews;
      });
      setNews(updatedNews);

      toast.success('Notificação enviada com sucesso');
    } catch (err) {
      toast.error(
        'Erro ao enviar a notificação, tente novamente em alguns instantes',
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <S.Page>
      <Header />

      <S.Main>
        <div className="section-title">
          <h1>Cadastrar Nova Notícia</h1>
        </div>

        <NewNewsForm />

        <div className="section-title">
          <h1>Lista de Notícias</h1>
          {newsBadges.activeNews > 0 && (
            <span>
              {newsBadges.activeNews} ativo{newsBadges.activeNews > 1 && 's'}
            </span>
          )}
        </div>
        <div className="news-list">
          {loading && <ImSpinner className="icon-spin" />}
          {news.map(newsList => (
            <News key={newsList.id} data={newsList}>
              <>
                <button
                  type="button"
                  className="notification-button"
                  onClick={() => handleSendNotification(newsList)}
                >
                  {!loading ? <FiRadio /> : <ImSpinner className="icon-spin" />}
                </button>
                <button
                  type="button"
                  className="edit-button"
                  onClick={() => toggleModalVisibility(newsList.id)}
                >
                  {!loading ? <FiEdit /> : <ImSpinner className="icon-spin" />}
                </button>
                <button
                  type="button"
                  className="delete-button"
                  onClick={() => deleteNews(newsList.id)}
                >
                  {!loading ? (
                    <IoTrashOutline />
                  ) : (
                    <ImSpinner className="icon-spin" />
                  )}
                </button>
              </>
            </News>
          ))}
          <EditNewsModal
            editingNewsId={editingNewsId}
            toggleModalVisibility={toggleModalVisibility}
          />
        </div>
      </S.Main>
    </S.Page>
  );
}
