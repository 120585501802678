import { useEffect, useState } from 'react';
import { database } from '../services/firebase';

export enum AccountStatus { // eslint-disable-line
  REJECTED = 'rejected',
  PENDING = 'pending',
  APPROVED = 'approved',
}

export type ClientType = {
  id: string;
  name: string;
  email: string;
  accountStatus: AccountStatus;
  createdAt: string;
  avatar?: string;
  zip?: string;
  street?: string;
  number?: number | string;
  district?: string;
  complement?: string;
  city?: string;
  state?: string;
  phone?: string;
  whatsapp?: string;
  contact?: string;
};

type FirebaseClientData = {
  name: string;
  email: string;
  accountStatus: AccountStatus;
  createdAt: string;
  avatar?: string;
  address?: {
    zip?: string;
    street?: string;
    number?: number | string;
    district?: string;
    complement?: string;
    city?: string;
    state?: string;
  };
  phone?: string;
  whatsapp?: string;
  contact?: string;
};

type FirebaseClients = Record<string, FirebaseClientData>;

type UseClientsReturnType = {
  clients: ClientType[];
  loading: boolean;
};

export function useClients(): UseClientsReturnType {
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState<ClientType[]>([]);

  function sortClients(unsortedClients: ClientType[]): ClientType[] {
    const sortedClients = unsortedClients.sort(
      (a: ClientType, b: ClientType) => {
        if (a.accountStatus === 'pending' && b.accountStatus === 'approved')
          return -1;
        if (a.accountStatus === 'approved' && b.accountStatus === 'pending')
          return 1;
        if (a.accountStatus === 'pending' && b.accountStatus === 'rejected')
          return -1;
        if (a.accountStatus === 'rejected' && b.accountStatus === 'pending')
          return 1;
        if (a.accountStatus === 'approved' && b.accountStatus === 'rejected')
          return -1;
        if (a.accountStatus === 'rejected' && b.accountStatus === 'approved')
          return 1;
        if (a.createdAt > b.createdAt) return -1;
        if (a.createdAt < b.createdAt) return 1;

        return 0;
      },
    );

    return sortedClients;
  }

  function normalizeClient(client: FirebaseClientData): Omit<ClientType, 'id'> {
    const normalizedClient = client;

    if (normalizedClient.address) {
      Object.entries(normalizedClient.address).forEach(([key, value]) => {
        // @ts-ignore
        normalizedClient[key] = value;
      });

      delete normalizedClient.address;
    }

    return normalizedClient;
  }

  useEffect(() => {
    const clientsRef = database.ref('clients');

    clientsRef.once('value', client => {
      const databaseClient: FirebaseClients = client.val();

      if (!databaseClient) return;

      const parsedClients = Object.entries(databaseClient).map(
        ([key, value]) => {
          const normalizedClients = {
            id: key,
            ...normalizeClient(value),
          };

          return normalizedClients;
        },
      );

      const sortedClients = sortClients([...parsedClients]);

      setClients(sortedClients);
      setLoading(false);
    });

    return () => {
      clientsRef.off('value');
    };
  }, []);

  useEffect(() => {
    const clientsRef = database.ref('clients');

    if (!loading) {
      clientsRef.on('child_added', firebaseClient => {
        if (!clients.find(client => client.id === firebaseClient.key)) {
          const normalizedClient = normalizeClient(firebaseClient.val());

          const parsedClient = {
            id: firebaseClient.key ?? normalizedClient.name,
            ...normalizedClient,
          };

          setClients(previousState => [parsedClient, ...previousState]);
        }
      });
    }

    return () => {
      clientsRef.off('child_added');
    };
  }, [loading, clients]);

  useEffect(() => {
    const clientsRef = database.ref('clients');

    if (!loading) {
      clientsRef.on('child_changed', firebaseClient => {
        const clientKey = firebaseClient.key;

        const parsedClient = normalizeClient(firebaseClient.val());

        const updatedClient = {
          id: clientKey ?? parsedClient.name,
          ...parsedClient,
        };

        const updatedClients = clients.map(client =>
          client.id === clientKey ? updatedClient : client,
        );

        const outdatedClient = clients.find(client => client.id === clientKey);

        if (updatedClient.accountStatus !== outdatedClient?.accountStatus) {
          const sortedClients = sortClients([...updatedClients]);
          setClients(sortedClients);
        } else {
          setClients(updatedClients);
        }
      });
    }

    return () => {
      clientsRef.off('child_changed');
    };
  }, [loading, clients]);

  return { clients, loading };
}
