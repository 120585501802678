import { useAuth } from '../../../hooks/useAuth';

import { Loading } from '../../Loading';

import illustrationImg from '../../../assets/images/illustration.svg';
import googleIconImg from '../../../assets/images/google-icon.svg';

import * as S from './style';

export function SignIn(): JSX.Element {
  const { loading, signInWithGoogle } = useAuth();

  async function handleSignInToDashboardAsAdmin() {
    await signInWithGoogle();
  }

  return (
    <S.PageAuth>
      {loading ? (
        <Loading />
      ) : (
        <>
          <S.Aside>
            <img
              src={illustrationImg}
              alt="Ilustração simbolizando o cadastro de usuários"
            />
            <strong>Cadastre usuários de forma fácil e rápida!</strong>
            <p>Aqui vai algum outro texto que eu ainda não sei</p>
          </S.Aside>
          <S.Main>
            <div className="main-content">
              <button type="button" onClick={handleSignInToDashboardAsAdmin}>
                <img src={googleIconImg} alt="Logo do Google" />
                Entrar com o Google
              </button>
            </div>
          </S.Main>
        </>
      )}
    </S.PageAuth>
  );
}
