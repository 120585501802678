import { Link, useHistory } from 'react-router-dom';
import { ImSpinner } from 'react-icons/im';

import * as S from './style';

import { useAuth } from '../../hooks/useAuth';
import { HeaderInfo } from '../HeaderInfo';

import logoImg from '../../assets/images/extended-logo.svg';
import { Button } from '../Button';

export function Header(): JSX.Element {
  const { user, signOut } = useAuth();

  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  async function handleSignOut() {
    await signOut();

    history.push('/');
  }

  const activeRoute = pathname.split('/dashboard');

  return (
    <S.Header>
      <div className="content">
        <Link to="/dashboard">
          <img src={logoImg} alt="Socius" />
        </Link>
        <nav>
          <ul>
            <li>
              <Link
                to="/dashboard"
                className={!activeRoute[1].length ? 'active' : ''}
              >
                Usuários
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/noticias"
                className={pathname.includes('/noticias') ? 'active' : ''}
              >
                Notícias
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/beneficios"
                className={pathname.includes('/beneficios') ? 'active' : ''}
              >
                Benefícios
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/configuracoes"
                className={pathname.includes('/configuracoes') ? 'active' : ''}
              >
                Configurações
              </Link>
            </li>
          </ul>
        </nav>
        <div>
          {user?.email ? <HeaderInfo /> : <ImSpinner className="icon-spin" />}
          <Button type="button" isOutlined onClick={handleSignOut}>
            Sair
          </Button>
        </div>
      </div>
    </S.Header>
  );
}
