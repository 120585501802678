import { AuthContextProvider } from './AuthContext';
import { ProfileContextProvider } from './ProfileContext';
import { UsersContextProvider } from './UsersContext';
import { BenefitsContextProvider } from './BenefitsContext';
import { NewsContextProvider } from './NewsContext';

interface Props {
  children?: React.ReactNode;
}

function ContextProvider({ children }: Props): JSX.Element {
  return (
    <AuthContextProvider>
      <ProfileContextProvider>
        <UsersContextProvider>
          <BenefitsContextProvider>
            <NewsContextProvider>{children}</NewsContextProvider>
          </BenefitsContextProvider>
        </UsersContextProvider>
      </ProfileContextProvider>
    </AuthContextProvider>
  );
}

export default ContextProvider;
