import { ImSpinner } from 'react-icons/im';
import { FiEdit, FiRadio } from 'react-icons/fi';
import { IoTrashOutline } from 'react-icons/io5';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { isAfter, sub } from 'date-fns';
import { useAuth } from '../../hooks/useAuth';

import * as S from './style';

import { NewBenefitForm } from '../../components/NewBenefitForm';
import { useBenefits } from '../../hooks/useBenefits';
import { Benefit } from '../../components/Benefit';
import { database } from '../../services/firebase';
import { EditBenefitsModal } from '../../components/EditBenefitsModal';
import { BenefitType } from '../../contexts/BenefitsContext';
import {
  NotificationRefs,
  NotificationTypes,
  sendNotification,
  SendNotificationProps,
} from '../../services/notification';
import { useUsers } from '../../hooks/useUsers';
import { UserType } from '../../contexts/UsersContext';
import { Header } from '../../components/Header';

export function Beneficios(): JSX.Element {
  const { user, idToken } = useAuth();
  const { benefits, setBenefits, loading, setLoading } = useBenefits();
  const { users } = useUsers();

  const [editingBenefitsId, setEditingBenefitsId] = useState('');

  const benefitsBadges = benefits.reduce(
    (accumulator: { activeBenefits: number }) => {
      return { ...accumulator, activeBenefits: accumulator.activeBenefits + 1 };
    },
    { activeBenefits: 0 },
  );

  function toggleModalVisibility(benefitsId?: string) {
    if (benefitsId) setEditingBenefitsId(benefitsId);
    else setEditingBenefitsId('');
  }

  async function deleteBenefit(benefitId: string) {
    if (loading) return;

    const benefitRef = await database.ref(
      `clients/${user?.id}/benefits/${benefitId}`,
    );

    await benefitRef.remove();

    const updatedBenefits = benefits.filter(
      mapbenefit => mapbenefit.id !== benefitId,
    );

    setBenefits(updatedBenefits);

    toast.success('Benefício excluído com sucesso', {
      position: 'bottom-center',
    });
  }

  async function handleSendNotification(benefit: BenefitType) {
    setLoading(true);
    try {
      const { notificationSentAt } = benefit;

      if (notificationSentAt) {
        const formattedNotificationSentAt = new Date(notificationSentAt);
        const twoDaysAgo = sub(new Date(), { hours: 48 });

        if (isAfter(formattedNotificationSentAt, twoDaysAgo)) {
          setLoading(false);
          toast.error(
            'É permitido uma notificação por benefício a cada 48 horas',
          );
          return;
        }
      }

      const recipients = users.reduce(
        (reduceRecipients: string[], reduceUser: UserType) => {
          if (reduceUser.expoPushToken)
            return [...reduceRecipients, reduceUser.expoPushToken];
          return reduceRecipients;
        },
        [],
      );

      const notification: SendNotificationProps = {
        config: {
          ref: NotificationRefs.BENEFIT,
          type:
            benefit?.updatedAt !== benefit.createdAt
              ? NotificationTypes.UPDATE
              : NotificationTypes.NEW,
        },
        data: benefit,
        recipients,
        user: {
          name: user?.name || null,
          idToken,
        },
      };

      await sendNotification(notification);
      const updatedSentTime = Date.now();

      const benefitRef = database.ref(
        `clients/${user?.id}/benefits/${benefit.id}`,
      );
      await benefitRef.update({
        notificationSentAt: updatedSentTime,
      });
      const updatedBenefits = benefits.map(mapBenefit => {
        if (mapBenefit.id === benefit.id)
          return {
            ...mapBenefit,
            notificationSentAt: updatedSentTime,
          };

        return mapBenefit;
      });
      setBenefits(updatedBenefits);

      toast.success('Notificação enviada com sucesso');
    } catch (err) {
      toast.error(
        'Erro ao enviar a notificação, tente novamente em alguns instantes',
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <S.Page>
      <Header />

      <S.Main>
        <div className="section-title">
          <h1>Cadastrar Novo Benefício</h1>
        </div>

        <NewBenefitForm />

        <div className="section-title">
          <h1>Lista de Benefícios</h1>
          {benefitsBadges.activeBenefits > 0 && (
            <span>
              {benefitsBadges.activeBenefits} ativo
              {benefitsBadges.activeBenefits > 1 && 's'}
            </span>
          )}
        </div>
        <div className="benefits-list">
          {loading && <ImSpinner className="icon-spin" />}
          {benefits.map(listBenefit => (
            <Benefit key={listBenefit.id} data={listBenefit}>
              <>
                <button
                  type="button"
                  className="notification-button"
                  onClick={() => handleSendNotification(listBenefit)}
                >
                  {!loading ? <FiRadio /> : <ImSpinner className="icon-spin" />}
                </button>
                <button
                  type="button"
                  className="edit-button"
                  onClick={() => toggleModalVisibility(listBenefit.id)}
                >
                  {!loading ? <FiEdit /> : <ImSpinner className="icon-spin" />}
                </button>
                <button
                  type="button"
                  className="delete-button"
                  onClick={() => deleteBenefit(listBenefit.id)}
                >
                  {!loading ? (
                    <IoTrashOutline />
                  ) : (
                    <ImSpinner className="icon-spin" />
                  )}
                </button>
              </>
            </Benefit>
          ))}
          <EditBenefitsModal
            editingBenefitsId={editingBenefitsId}
            toggleModalVisibility={toggleModalVisibility}
          />
        </div>
      </S.Main>
    </S.Page>
  );
}
