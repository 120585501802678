import styled from 'styled-components';

export const Page = styled.div``;

export const Main = styled.main`
  max-width: 800px;
  margin: 0 auto;

  .section-title {
    margin: 32px 0 24px;
    display: flex;

    align-items: center;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      color: #29292e;
    }
  }
`;

export const SettingItem = styled.div`
  display: flex;
  flex: 1;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;

  p {
    font-size: 1rem;
  }

  div.buttons-group {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
`;
