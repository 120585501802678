import { forwardRef, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { ImSpinner } from 'react-icons/im';
import * as Yup from 'yup';
import { isAfter } from 'date-fns';
import InputMask from 'react-input-mask';

import { Button } from '../Button/style';
import { useAuth } from '../../hooks/useAuth';
import { useUsers } from '../../hooks/useUsers';
import {
  database,
  storage,
  translateAuthErrors,
} from '../../services/firebase';

import { DatePickerField } from '../DatePicker';

import * as S from './style';

type FormValues = {
  subscriptionNumber: number | string;
  title: string;
  subscribedAt: Date;
  subscriptionValidity?: Date | null;
  phone: string;
  name: string;
  birth: Date;
};

type EditUserModalProps = {
  editingUserId: string;
  toggleModalVisibility: (userId?: string) => void;
};

export function EditUserModal({
  editingUserId,
  toggleModalVisibility,
}: EditUserModalProps): JSX.Element {
  const { loading, users, setUsers } = useUsers();
  const { user } = useAuth();

  const [oldUserPhone, setOldUserPhone] = useState<unknown>(null);

  const formValidationSchema = Yup.object({
    subscriptionNumber: Yup.string().required('Preencha a matrícula'),
    title: Yup.string()
      .min(3, 'A titularidaede deve ter pelo menos 3 caracteres')
      .required('Preencha a titularidade'),
    subscribedAt: Yup.date()
      .max(new Date(), 'A data de afiliação deve ser hoje ou anterior')
      .required('Preencha a data de afiliação'),
    subscriptionValidity: Yup.date()
      .test({
        name: 'validity',
      test: function(value) { // eslint-disable-line
        return !value || isAfter(new Date(value ?? ''), this.parent.subscribedAt); // eslint-disable-line
        },
        message: 'A data de validade deve ser posterior a afiliação',
        exclusive: true,
      })
      .nullable(),
    phone: Yup.string()
      .min(10, 'O Celular deve ter no mínimo 10 caracteres')
      .required('Preencha o Celular'),
    name: Yup.string()
      .min(8, 'O nome deve ter no mínimo 8 caracteres')
      .required('Preencha o nome do afiliado'),
    birth: Yup.date().required('Preencha a data de nascimento'),
  });

  const form = useFormik({
    initialValues: {
      subscriptionNumber: '',
      title: '',
      subscribedAt: new Date(),
      subscriptionValidity: null,
      phone: '',
      name: '',
      birth: new Date(),
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSaveEditedUser, // eslint-disable-line
  });

  useEffect(() => {
    if (editingUserId) {
      const userToEditData = users.find(
        findUser => findUser.id === editingUserId,
      );

      if (userToEditData) {
        const parsedUser = {
          ...userToEditData,
          subscribedAt: new Date(userToEditData.subscribedAt),
          subscriptionValidity: userToEditData.subscriptionValidity
            ? new Date(userToEditData.subscriptionValidity)
            : null,
          birth: new Date(userToEditData.birth),
        };

        Object.entries(parsedUser).forEach(([key, value]) => {
          form.setFieldValue(key, value);
          if (key === 'phone') {
            const typedValue = value as string;
            let unmaskedPhone = typedValue.replace('(', '');
            unmaskedPhone = unmaskedPhone.replace(')', '');
            unmaskedPhone = unmaskedPhone.replace(' ', '');
            unmaskedPhone = unmaskedPhone.replace('-', '');
            setOldUserPhone(unmaskedPhone);
          }
        });
      }
    }
  }, [editingUserId]); // eslint-disable-line

  function handleCloseModal() {
    toggleModalVisibility();
    form.resetForm();
  }

  async function handleSaveEditedUser(values: FormValues) {
    if (loading) {
      toast.error('Aguarde o carregamento dos usuários', {
        icon: '⏳',
        position: 'bottom-center',
      });
      return;
    }

    const parsedValues = {
      ...values,
      subscriptionNumber: values.subscriptionNumber,
      subscribedAt: Date.parse(String(values?.subscribedAt ?? '')),
      subscriptionValidity: values.subscriptionValidity
        ? Date.parse(String(values?.subscriptionValidity ?? ''))
        : null,
      birth: Date.parse(String(values?.birth ?? '')),
      updatedAt: Date.parse(String(new Date())),
      id: null,
    };

    if (
      users.some(
        hookUser =>
          hookUser.phone === parsedValues.phone &&
          hookUser.id !== editingUserId,
      )
    ) {
      toast.error('Existe outro usuário com esse telefone');
      return;
    }

    try {
      const userRef = database.ref(
        `clients/${user?.id}/users/${editingUserId}`,
      );

      await userRef.update(parsedValues);

      if (oldUserPhone !== values.phone) {
        const unmaskedPhone = values.phone
          .replace('(', '')
          .replace(')', '')
          .replace(' ', '')
          .replace('-', '')
          .replace('_', '');

        const updatedPhonesClients = database.ref(
          `phones_clients/${unmaskedPhone}`,
        );

        await updatedPhonesClients.push({
          clientId: user?.id,
          userId: userRef.key,
        });

        const oldPhonesClients = database.ref(`phones_clients/${oldUserPhone}`);

        oldPhonesClients.once('value', async oldPhoneClient => {
          const databaseClient: Record<
            string,
            { clientId: string; userId: string }
          > = oldPhoneClient.val();

          if (!databaseClient) return;

          if (Object.keys(databaseClient).length === 1)
            await oldPhonesClients.remove();

          const flatClient = Object.entries(databaseClient).find(
            ([, value]) => value.userId === editingUserId,
          );

          if (flatClient && typeof flatClient[0] === 'string')
            await database
              .ref(`phones_clients/${oldUserPhone}/${flatClient[0]}`)
              .remove();
        });
      }

      const updatedUsers = users.map(mapUser =>
        mapUser.id === editingUserId
          ? {
              ...mapUser,
              ...values,
              subscriptionValidity: values.subscriptionValidity || undefined,
              subscriptionNumber: values.subscriptionNumber,
            }
          : mapUser,
      );

      setUsers(updatedUsers);

      toast.success('Alteração salva com sucesso!', {
        position: 'bottom-center',
      });

      toggleModalVisibility();
    } catch (error) {
      const { code } = error as { code: string };
      const message = translateAuthErrors(code);
      toast.error(message);
    }
  }

  const CustomMaskedInput = forwardRef((props, _) => (
    <InputMask type="text" mask="99/99/9999" {...props} />
  ));

  async function handlePasswordReset() {
    if (loading) return;

    const userRef = database.ref(`clients/${user?.id}/users/${editingUserId}`);

    await userRef.update({
      password: null,
    });

    toast.success('Senha resetada com sucesso!', {
      position: 'bottom-center',
    });
  }

  async function handleAvatarReset() {
    if (loading) return;

    try {
      const userPath = `clients/${user?.id}/users`;

      const userDatabaseRef = database.ref(`${userPath}/${editingUserId}`);
      const userStorageRef = storage.ref(userPath).child(editingUserId);

      await userDatabaseRef.update({
        userAvatar: null,
      });

      const images = await userStorageRef.listAll();
      const deletePromises = images.items.map(image => image.delete());
      await Promise.all(deletePromises);

      const updatedUsers = users.map(mapUser => {
        if (mapUser.id === editingUserId) {
          const userWithoutAvatar = mapUser;
          delete userWithoutAvatar.userAvatar;
          return userWithoutAvatar;
        }
        return mapUser;
      });

      setUsers(updatedUsers);

      toast.success('Imagem resetada com sucesso!', {
        position: 'bottom-center',
      });
    } catch (err) {
      toast.error(
        'Houve um erro ao resetar a imagem, tente novamente em alguns instantes',
        {
          position: 'bottom-center',
        },
      );
    }
  }

  return (
    <S.ModalContainer
      isOpen={!!editingUserId}
      onRequestClose={() => handleCloseModal()}
    >
      <S.Content>
        <header>
          <h1>Edição de Usuário</h1>
        </header>
        <main>
          <S.Form onSubmit={form.handleSubmit}>
            <div className="client-info">
              <div className="input-wrapper">
                <label htmlFor="editSubscriptionNumber">Matrícula</label>
                <input
                  id="editSubscriptionNumber"
                  type="text"
                  placeholder="Matrícula"
                  {...form.getFieldProps('subscriptionNumber')}
                />
                {form.touched.subscriptionNumber &&
                  form.errors.subscriptionNumber && (
                    <span className="input-error">
                      {form.errors.subscriptionNumber}
                    </span>
                  )}
              </div>
              <div className="input-wrapper">
                <label htmlFor="editTitle">Titularidade</label>
                <input
                  id="editTitle"
                  type="text"
                  placeholder="Titularidade"
                  {...form.getFieldProps('title')}
                />
                {form.touched.title && form.errors.title && (
                  <span className="input-error">{form.errors.title}</span>
                )}
              </div>
            </div>
            <div className="client-info">
              <div className="input-wrapper">
                <label htmlFor="editSubscribedAt">Data de Afiliação</label>
                <DatePickerField
                  id="editSubscribedAt"
                  className="datepicker"
                  placeholderText="Data da Filiação"
                  setFieldValue={form.setFieldValue}
                  maxDate={new Date()}
                  {...form.getFieldProps('subscribedAt')}
                  customInput={<CustomMaskedInput />}
                />
                {form.touched.subscribedAt && form.errors.subscribedAt && (
                  <span className="input-error">
                    {form.errors.subscribedAt}
                  </span>
                )}
              </div>
              <div className="input-wrapper">
                <label htmlFor="editSubscriptionValidity">
                  Validade da Afiliação
                </label>
                <DatePickerField
                  id="editSubscriptionValidity"
                  className="datepicker"
                  placeholderText="Validade da Afiliação"
                  setFieldValue={form.setFieldValue}
                  {...form.getFieldProps('subscriptionValidity')}
                  customInput={<CustomMaskedInput />}
                />
                {form.touched.subscriptionValidity &&
                  form.errors.subscriptionValidity && (
                    <span className="input-error">
                      {form.errors.subscriptionValidity}
                    </span>
                  )}
              </div>
            </div>
            <div className="user-info">
              <div className="input-wrapper large">
                <label htmlFor="editName">Nome do Afiliado</label>
                <input
                  id="editName"
                  type="text"
                  placeholder="Nome Completo"
                  {...form.getFieldProps('name')}
                />
                {form.touched.name && form.errors.name && (
                  <span className="input-error">{form.errors.name}</span>
                )}
              </div>
            </div>
            <div className="user-info">
              <div className="input-wrapper">
                <label htmlFor="editPhone">Celular</label>
                <InputMask
                  id="editPhone"
                  type="text"
                  placeholder="Celular"
                  mask="(99) 99999-9999"
                  {...form.getFieldProps('phone')}
                />
                {form.touched.phone && form.errors.phone && (
                  <span className="input-error">{form.errors.phone}</span>
                )}
              </div>
              <div className="input-wrapper">
                <label htmlFor="editBirth">Data de Nascimento</label>
                <DatePickerField
                  id="editBirth"
                  className="datepicker"
                  placeholderText="Data de Nascimento"
                  showYearDropdown
                  dropdownMode="select"
                  setFieldValue={form.setFieldValue}
                  {...form.getFieldProps('birth')}
                  customInput={<CustomMaskedInput />}
                />
                {form.touched.birth && form.errors.birth && (
                  <span className="input-error">{form.errors.birth}</span>
                )}
              </div>
            </div>
            <p>
              <button type="button" onClick={handlePasswordReset}>
                Resetar Senha
              </button>
              <button type="button" onClick={handleAvatarReset}>
                Resetar Imagem
              </button>
            </p>
            <footer>
              <Button
                type="button"
                isCancelButton
                onClick={() => handleCloseModal()}
              >
                Cancelar
              </Button>
              <Button type="submit" disabled={form.isSubmitting}>
                {!form.isSubmitting ? (
                  'Salvar'
                ) : (
                  <ImSpinner className="icon-spin" />
                )}
              </Button>
            </footer>
          </S.Form>
        </main>
      </S.Content>
    </S.ModalContainer>
  );
}
