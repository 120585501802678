import { useEffect, useState } from 'react';
import { ImSpinner } from 'react-icons/im';
import * as S from './style';

import { database } from '../../services/firebase';

import { Header } from '../../components/Header';
import { Button } from '../../components/Button';
import { useAuth } from '../../hooks/useAuth';

export function Settings(): JSX.Element {
  const { user, loading } = useAuth();
  const [subscriptionValidity, setSubscriptionValidity] = useState(true);

  useEffect(() => {
    if (typeof user?.settings?.subscriptionValidity === 'boolean')
      setSubscriptionValidity(user?.settings?.subscriptionValidity);
  }, [user]);

  async function updateSubscriptionValidity(state: boolean) {
    const clientRef = database.ref(`/clients/${user?.id}/settings`);

    await clientRef.update({
      subscriptionValidity: state,
    });
  }

  async function enableSubscriptionValidity() {
    await updateSubscriptionValidity(true);

    setSubscriptionValidity(true);
  }

  async function disableSubscriptionValidity() {
    await updateSubscriptionValidity(false);

    setSubscriptionValidity(false);
  }

  return (
    <S.Page>
      <Header />

      <S.Main>
        <div className="section-title">
          <h1>Configurações da Conta</h1>
        </div>

        <h1>Cadastro de Usuários</h1>

        <S.SettingItem>
          <p>Mostrar Data de Vencimento</p>
          {loading ? (
            <ImSpinner className="icon-spin" />
          ) : (
            <div className="buttons-group">
              <Button
                isOutlined={!subscriptionValidity}
                onClick={enableSubscriptionValidity}
                buttonColor="#5afd5a"
              >
                {subscriptionValidity ? 'Ativado' : 'Ativar'}
              </Button>
              <Button
                isOutlined={subscriptionValidity}
                onClick={disableSubscriptionValidity}
                buttonColor="#fd5a5a"
              >
                {!subscriptionValidity ? 'Desativado' : 'Desativar'}
              </Button>
            </div>
          )}
        </S.SettingItem>
      </S.Main>
    </S.Page>
  );
}
