import { ReactNode } from 'react';
import { formatDate } from '../../utils/formatDate';

import * as S from './style';

type BenefitProps = {
  data: {
    title: string;
    description: string;
    featured_image?: string;
  };
  children?: ReactNode;
  isInactive?: Date | null;
};

export function Benefit({
  data,
  isInactive = null,
  children = null,
}: BenefitProps): JSX.Element {
  return (
    <S.Benefit isInactive={!!isInactive}>
      {data.featured_image && (
        <img
          src={data.featured_image}
          alt={`Imagem de destaque do benefício "${data.title}"`}
        />
      )}
      <div className="benefit-info">
        <p>
          <strong>{data.title}</strong>
        </p>
        <span>
          {data.description.slice(0, 140)}
          {data.description.length > 140 && '...'}
        </span>
        {isInactive && (
          <span>
            Excluído em: <strong>{formatDate(isInactive)}</strong>
          </span>
        )}
      </div>
      <footer>
        <div>{children}</div>
      </footer>
    </S.Benefit>
  );
}
