import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Link } from 'react-router-dom';

import { ImSpinner } from 'react-icons/im';

import { Button } from '../../components/Button';

import illustrationImg from '../../assets/images/illustration.svg';

import * as S from './style';
import { useAuth } from '../../hooks/useAuth';

type FormValues = {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

export function SignUp(): JSX.Element {
  const { signUpWithEmailAndPassword } = useAuth();

  const formValidationSchema = Yup.object({
    name: Yup.string()
      .min(10, 'O nome deve ter no mínimo 10 caracteres')
      .required('Preencha o nome da instituição'),
    email: Yup.string().email('Email inválido').required('Preencha seu email'),
    password: Yup.string()
      .min(6, 'A senha deve ter no mínimo 6 caracteres')
      .required('Preencha sua senha'),
    passwordConfirmation: Yup.string()
      .test('passwords-match', 'As senhas devem ser iguais', function (value) { // eslint-disable-line
        return this.parent.password === value; // eslint-disable-line
      })
      .required('Preencha a confirmação de senha'),
  });

  async function handleSignUpNewUser(values: FormValues) {
    const { name, email, password } = values;

    await signUpWithEmailAndPassword(name, email, password);
  }

  const form = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSignUpNewUser,
  });

  return (
    <S.PageAuth>
      <S.Aside>
        <img
          src={illustrationImg}
          alt="Ilustração simbolizando o cadastro de usuários"
        />
        <strong>Cadastre usuários de forma fácil e rápida!</strong>
        <p>Aqui vai algum outro texto que eu ainda não sei</p>
      </S.Aside>
      <S.Main>
        <div className="main-content">
          <form onSubmit={form.handleSubmit}>
            <input
              id="name"
              type="text"
              placeholder="Nome da instituição"
              {...form.getFieldProps('name')}
            />
            {form.touched.name && form.errors.name && (
              <span className="input-error">{form.errors.name}</span>
            )}
            <input
              id="email"
              type="email"
              placeholder="Seu email"
              {...form.getFieldProps('email')}
            />
            {form.touched.email && form.errors.email && (
              <span className="input-error">{form.errors.email}</span>
            )}
            <input
              id="password"
              type="password"
              placeholder="Sua senha"
              {...form.getFieldProps('password')}
            />
            {form.touched.password && form.errors.password && (
              <span className="input-error">{form.errors.password}</span>
            )}
            <input
              id="passwordConfirmation"
              type="password"
              placeholder="Confirme sua senha"
              {...form.getFieldProps('passwordConfirmation')}
            />
            {form.touched.passwordConfirmation &&
              form.errors.passwordConfirmation && (
                <span className="input-error">
                  {form.errors.passwordConfirmation}
                </span>
              )}
            <Button type="submit" disabled={form.isSubmitting}>
              {!form.isSubmitting ? (
                'Cadastrar'
              ) : (
                <ImSpinner className="icon-spin" />
              )}
            </Button>
          </form>
        </div>
        <footer className="content-footer">
          <p>
            Já tem uma conta? <Link to="/">Entrar</Link>
          </p>
        </footer>
      </S.Main>
    </S.PageAuth>
  );
}
