import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { ImSpinner } from 'react-icons/im';
import * as Yup from 'yup';

import { Button } from '../Button/style';
import { useAuth } from '../../hooks/useAuth';
import {
  database,
  storage,
  translateAuthErrors,
} from '../../services/firebase';

import * as S from './style';
import { useNews } from '../../hooks/useNews';
import { FileDropzone } from '../FileDropzone';

type FormValues = {
  title: string;
  description: string;
  featuredImage: File | undefined;
  featuredImageUrl: string | null;
};

type EditNewsModalProps = {
  editingNewsId: string;
  toggleModalVisibility: (newsId?: string) => void;
};

export function EditNewsModal({
  editingNewsId,
  toggleModalVisibility,
}: EditNewsModalProps): JSX.Element {
  const { user } = useAuth();
  const { loading, news, setNews } = useNews();

  const [uploadLoading, setUploadLoading] = useState(false);

  const formValidationSchema = Yup.object({
    title: Yup.string()
      .min(5, 'O título deve ter pelo menos 5 caracteres')
      .required('Preencha o título'),
    description: Yup.string()
      .min(70, 'O corpo da notícia deve ter pelo menos 70 caracteres')
      .required('Preencha o corpo da notícia'),
    faturedImageUrl: Yup.string().nullable(true),
    featuredImage: Yup.mixed()
      .test(
        'fileFormat',
        'O tipos de arquivos aceitos são: JPG, JPEG e PNG',
        value => {
          if (!value || typeof value === 'string') return true;
          const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

          return value && SUPPORTED_FORMATS.includes(value.type);
        },
      )
      .test('fileSize', 'O tamanho máximo da imagem é 10Mb', value => {
        if (!value || typeof value === 'string') return true;
        return value && value.size <= 10485760;
      }),
  });

  const form = useFormik({
    initialValues: {
      title: '',
      description: '',
      featuredImage: undefined,
      featuredImageUrl: null,
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSaveEditedNews, // eslint-disable-line
  });

  useEffect(() => {
    if (editingNewsId) {
      const newsToEditData = news.find(
        findNews => findNews.id === editingNewsId,
      );

      if (newsToEditData) {
        Object.entries(newsToEditData).forEach(([key, value]) => {
          if (key === 'featured_image') {
            form.setFieldValue('featuredImageUrl', value);
          } else {
            form.setFieldValue(key, value);
          }
        });
      }
    }
  }, [editingNewsId]); // eslint-disable-line

  function handleCloseModal() {
    toggleModalVisibility();
    form.resetForm();
  }

  async function uploadNewsFeaturedImage(featuredImage: File | unknown) {
    try {
      if (!featuredImage || !(featuredImage instanceof File)) {
        return null;
      }

      setUploadLoading(true);

      const storageRef = storage
        .ref()
        .child(
          `clients/${user?.id}/news/${new Date().toISOString()}-${
            featuredImage?.name
          }`,
        );

      // @ts-ignore
      const uploadedFile = await storageRef.put(featuredImage);
      const downloadUrl = await uploadedFile.ref.getDownloadURL();

      setUploadLoading(false);
      return downloadUrl;
    } catch (e) {
      toast.error('Erro ao salvar a imagem da notícia', {
        position: 'bottom-center',
      });
      setUploadLoading(false);
    }
  }

  async function handleSaveEditedNews(values: FormValues) {
    if (loading) {
      toast.error('Aguarde o carregamento das notícias', {
        icon: '⏳',
        position: 'bottom-center',
      });
      return;
    }

    if (uploadLoading) {
      toast.error('Aguarde o upload da imagem', {
        icon: '⏳',
        position: 'bottom-center',
      });
      return;
    }

    try {
      const { featuredImage, featuredImageUrl, title, description } = values;

      const featuredImageDownloadUrl = await uploadNewsFeaturedImage(
        featuredImage,
      );

      const newsRef = database.ref(`clients/${user?.id}/news/${editingNewsId}`);

      const updatedNews = {
        title,
        description,
        featured_image: featuredImageDownloadUrl || featuredImageUrl,
      };

      await newsRef.update({
        ...updatedNews,
        updatedAt: Date.now(),
      });

      const updatedNewsList = news.map(mapNews =>
        mapNews.id === editingNewsId
          ? {
              ...mapNews,
              ...updatedNews,
              updatedAt: Date.now(),
            }
          : mapNews,
      );

      setNews(updatedNewsList);

      toast.success('Alteração salva com sucesso!', {
        position: 'bottom-center',
      });

      toggleModalVisibility();
    } catch (error) {
      const { code } = error as { code: string };
      const message = translateAuthErrors(code);
      toast.error(message);
    }
  }

  return (
    <S.ModalContainer
      isOpen={!!editingNewsId}
      onRequestClose={() => handleCloseModal()}
    >
      <S.Content>
        <header>
          <h1>Edição de Notícia</h1>
        </header>
        <main>
          <S.Form onSubmit={form.handleSubmit}>
            <div className="news-info">
              <div className="image-container">
                <FileDropzone
                  imagePreview={form.values.featuredImageUrl}
                  imageDimensions={{ width: 240, height: 135 }}
                  dimensionsToShow={{ width: 1920, height: 1080 }}
                  allowedFiles={['image/jpg', 'image/jpeg', 'image/png']}
                  buttonDescription="Imagem de Destaque"
                  name="featuredImage"
                  setFieldValue={form.setFieldValue}
                  formValue={form.values.featuredImage}
                />
              </div>
              <div className="input-wrapper">
                <input
                  id="title"
                  type="text"
                  placeholder="Título"
                  {...form.getFieldProps('title')}
                />
                {form.touched.title && form.errors.title && (
                  <span className="input-error">{form.errors.title}</span>
                )}
              </div>
              <div className="input-wrapper">
                <textarea
                  id="description"
                  placeholder="Corpo da Notícia"
                  rows={5}
                  {...form.getFieldProps('description')}
                />
                {form.touched.description && form.errors.description && (
                  <span className="input-error">{form.errors.description}</span>
                )}
              </div>
            </div>
            <footer>
              <Button
                type="button"
                isCancelButton
                onClick={() => handleCloseModal()}
              >
                Cancelar
              </Button>
              <Button type="submit" disabled={form.isSubmitting}>
                {!form.isSubmitting ? (
                  'Salvar'
                ) : (
                  <ImSpinner className="icon-spin" />
                )}
              </Button>
            </footer>
          </S.Form>
        </main>
      </S.Content>
    </S.ModalContainer>
  );
}
