import { createContext, ReactNode, useEffect, useState } from 'react';
import { isBefore } from 'date-fns';
import { useAuth } from '../hooks/useAuth';
import { database } from '../services/firebase';

export type NewsType = {
  id: string;
  title: string;
  description: string;
  featured_image?: string;
  createdAt: Date;
  updatedAt?: Date | number | null;
  notificationSentAt?: Date | number | null;
};

type FirebaseNews = Record<string, NewsType>;

export type NewsContextType = {
  loading: boolean;
  setLoading: (state: boolean) => void;
  news: NewsType[];
  setNews: (news: NewsType[]) => void;
};

type NewsContextProviderProps = {
  children: ReactNode;
};

export const NewsContext = createContext({} as NewsContextType);

export function NewsContextProvider({
  children,
}: NewsContextProviderProps): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState<NewsType[]>([]);

  const { user } = useAuth();

  function sortNews(unsortedNews: NewsType[]): NewsType[] {
    const sortedNews = unsortedNews.sort((a: NewsType, b: NewsType) => {
      if (isBefore(a.createdAt, b.createdAt)) return 1;
      if (isBefore(b.createdAt, a.createdAt)) return -1;

      return 0;
    });

    return sortedNews;
  }

  useEffect(() => {
    const newsRef = database.ref(`clients/${user?.id}/news`);

    newsRef.once('value', benefit => {
      const databaseNews: FirebaseNews = benefit.val();

      if (!databaseNews) {
        setLoading(false);
        return;
      }

      const parsedNews = Object.entries(databaseNews).map(([key, value]) => {
        return {
          id: key,
          title: value.title,
          description: value.description,
          featured_image: value.featured_image,
          createdAt: value.createdAt,
          updatedAt: value.updatedAt || null,
          notificationSentAt: value.notificationSentAt || null,
        };
      });

      const sortedNews = sortNews([...parsedNews]);

      setNews(sortedNews);
      setLoading(false);
    });

    return () => {
      newsRef.off('value');
    };
  }, [user?.id]);

  // useEffect(() => {
  //   const newsRef = database.ref(`clients/${user?.id}/news`);

  //   newsRef.on('child_added', firebaseUser => {
  //     const parsedNews = {
  //       id: firebaseUser.key,
  //       ...firebaseUser.val(),
  //     };

  //     if (!news.find(stateNews => stateNews.id === firebaseUser.key)) {
  //       setNews(previousState => [parsedNews, ...previousState]);
  //     }
  //   });

  //   return () => {
  //     newsRef.off('child_added');
  //   };
  // }, [news, user?.id]);

  // useEffect(() => {
  //   const newsRef = database.ref(`clients/${user?.id}/news`);

  //   newsRef.on('child_changed', firebaseUser => {
  //     const newsKey = firebaseUser.key;

  //     const updatedClient = {
  //       id: newsKey,
  //       ...firebaseUser.val(),
  //     };

  //     const updatedNews = news.map(stateNews =>
  //       stateNews.id === newsKey ? updatedClient : stateNews,
  //     );

  //     setNews(updatedNews);
  //   });

  //   return () => {
  //     newsRef.off('child_changed');
  //   };
  // }, [news, user?.id]);

  // useEffect(() => {
  //   const newsRef = database.ref(`clients/${user?.id}/news`);

  //   newsRef.on('child_removed', firebaseNews => {
  //     const newsKey = firebaseNews.key;

  //     const updatedNews = news.filter(stateNews => stateNews.id !== newsKey);

  //     setNews(updatedNews);
  //   });

  //   return () => {
  //     newsRef.off('child_removed');
  //   };
  // }, [news, user?.id]);

  return (
    <NewsContext.Provider
      value={{
        loading,
        setLoading,
        news,
        setNews,
      }}
    >
      {children}
    </NewsContext.Provider>
  );
}
