import firebase from 'firebase/app';

import 'firebase/auth'; // eslint-disable-line
import 'firebase/database'; // eslint-disable-line
import 'firebase/storage'; // eslint-disable-line

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const database = firebase.database();
const storage = firebase.storage();

function translateAuthErrors(errorCode: string): string {
  switch (errorCode) {
    case 'auth/invalid-email':
    case 'auth/wrong-password':
      return 'Email ou Senha incorretos';
    case 'auth/user-disabled':
    case 'auth/user-not-found':
      return 'Usuário não encontrado!';
    case 'auth/email-already-in-use':
      return 'Email já cadastrado';
    case 'auth/operation-not-allowed':
      return 'Serviço de Login indisponível';
    case 'auth/weak-password':
      return 'Senha fraca, tente uma mais forte';
    case 'auth/missing-information':
      return 'Preencha os dados da sua conta Google (nome e avatar)';
    case 'auth/disapproved-account':
      return 'Conta não aprovada';
    case 'auth/pending-approval':
      return 'Aprovação pendente';
    case 'auth/cancelled-popup-request':
      return 'Popup cancelado';
    case 'auth/account-exists-with-different-credential':
      return 'Conta já cadastrada';
    case 'auth/popup-blocked':
      return 'Seu navegador está bloqueando o Popup de login';
    case 'auth/popup-closed-by-user':
      return 'Login cancelado';
    default:
      return 'Ops, algo deu errado! Tente novamente.';
  }
}

export { firebase, auth, database, storage, translateAuthErrors };
