import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .file-dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-error {
      color: #ea4335;
      font-size: 13px;
      margin-top: 4px;
    }
  }

  .input-wrapper {
    display: flex;
    flex: 1;
    align-self: stretch;
    flex-direction: column;

    input {
      width: 100%;
      height: 50px;
      border-radius: 8px;
      padding: 0 16px;
      background: #fff;
      border: 1px solid #a8a8b3;
    }

    textarea {
      width: 100%;
      border-radius: 8px;
      padding: 16px;
      background: #fff;
      border: 1px solid #a8a8b3;
      resize: vertical;
    }

    .input-error {
      text-align: left;
      color: #ea4335;
      font-size: 13px;
      margin-top: 4px;
    }

    &.large {
      flex: 2;
    }
  }

  & + div {
    margin-top: 8px;
  }

  .form-footer {
    display: flex;
    align-self: stretch;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
  }
`;
