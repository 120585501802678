import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import toast from 'react-hot-toast';
import { ImSpinner } from 'react-icons/im';
import InputMask from 'react-input-mask';
import { useAuth } from '../../hooks/useAuth';

import * as S from './style';
import {
  database,
  storage,
  translateAuthErrors,
} from '../../services/firebase';
import { Button } from '../Button/style';
import { FileDropzone } from '../FileDropzone';

type FormValues = {
  name: string | null;
  avatar?: string | null;
  zip: string;
  street: string;
  number: number | string;
  district: string;
  complement: string;
  city: string;
  state: string;
  phone: string;
  whatsapp: string;
  contact: string;
  userAvatar: File | undefined;
  userAvatarUrl: string | null;
};

type EditProfileModalProps = {
  isEditingProfile: boolean;
  toggleModalVisibility: () => void;
};

export function EditProfileModal({
  isEditingProfile,
  toggleModalVisibility,
}: EditProfileModalProps): JSX.Element {
  const [formStep, setFormStep] = useState(1);
  const { loading, user, updateUser, sendForgotPasswordEmail } = useAuth();

  const [uploadLoading, setUploadLoading] = useState(false);

  const formValidationSchema = Yup.object({
    name: Yup.string()
      .min(8, 'O nome deve ter no mínimo 8 caracteres')
      .required('Preencha o nome'),
    zip: Yup.number()
      .min(8, 'O nome deve ter no mínimo 8 caracteres')
      .required('Preencha o CEP'),
    street: Yup.string()
      .min(6, 'O nome deve ter no mínimo 6 caracteres')
      .required('Preencha o logradouro'),
    number: Yup.string().required('Preencha a número'),
    district: Yup.string().required('Preencha o bairro'),
    complement: Yup.string(),
    city: Yup.string().required('Preencha a cidade'),
    state: Yup.string().required('Preencha o estado'),
    phone: Yup.string()
      .min(10, 'O Telefone deve ter no mínimo 10 caracteres')
      .required('Preencha o telefone'),
    whatsapp: Yup.string().min(
      10,
      'O Whatsapp deve ter no mínimo 10 caracteres',
    ),
    contact: Yup.string()
      .email('Email de contato inválido')
      .required('Preencha o email de contato'),
    userAvatarUrl: Yup.string().nullable(true),
    userAvatar: Yup.mixed()
      .test(
        'fileFormat',
        'O tipos de arquivos aceitos são: JPG, JPEG e PNG',
        value => {
          if (!value || typeof value === 'string') return true;
          const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

          return value && SUPPORTED_FORMATS.includes(value.type);
        },
      )
      .test('fileSize', 'O tamanho máximo da imagem é 10Mb', value => {
        if (!value || typeof value === 'string') return true;
        return value && value.size <= 10485760;
      }),
  });

  const form = useFormik({
    initialValues: {
      name: '',
      zip: '',
      street: '',
      number: '',
      district: '',
      complement: '',
      city: '',
      state: '',
      phone: '',
      whatsapp: '',
      contact: '',
      userAvatar: undefined,
      userAvatarUrl: null,
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSaveEditedClient, // eslint-disable-line
  });

  useEffect(() => {
    if (isEditingProfile && user) {
      Object.entries(user).forEach(([key, value]) => {
        if (value && typeof value === 'object') {
          Object.entries(value).forEach(([deepKey, deepValue]) => {
            form.setFieldValue(deepKey, deepValue);
          });
        } else if (key === 'userAvatar') {
          form.setFieldValue('userAvatarUrl', value);
        } else {
          form.setFieldValue(key, value);
        }
      });
    }
  }, [isEditingProfile]); // eslint-disable-line

  useEffect(() => {
    if (
      form.errors.name ||
      form.errors.phone ||
      form.errors.whatsapp ||
      form.errors.contact
    ) {
      setFormStep(1);
    }
  }, [form.errors]);

  function handleCloseModal() {
    toggleModalVisibility();
  }

  async function uploadClientAvatar() {
    setUploadLoading(true);
    if (!form.values.userAvatar) return;

    try {
      const storageRef = storage
        .ref()
        .child(
          `clients/${user?.id}/profile/${new Date().toISOString()}-${
            form.values.userAvatar?.name
          }`,
        );

      // @ts-ignore
      const uploadedFile = await storageRef.put(form.values.userAvatar);
      const downloadUrl = await uploadedFile.ref.getDownloadURL();

      form.setFieldValue('userAvatarUrl', downloadUrl);
      form.setFieldValue('userAvatar', undefined);
      setUploadLoading(false);
    } catch (e) {
      toast.error('Erro ao salvar sua imagem de perfil', {
        position: 'bottom-center',
      });
      setUploadLoading(false);
    }
  }

  function purgeMask(value: string) {
    const updatedValue = value
      .replace('(', '')
      .replace(')', '')
      .replace('_', '')
      .replace(' ', '')
      .replace('-', '');

    return updatedValue;
  }

  async function handleSaveEditedClient(values: FormValues) {
    if (loading) {
      toast.error('Aguarde o carregamento dos usuários', {
        icon: '⏳',
        position: 'bottom-center',
      });
      return;
    }

    try {
      const {
        name,
        phone,
        whatsapp,
        contact,
        userAvatarUrl,
        zip,
        district,
        street,
        number,
        complement,
        city,
        state,
      } = values;

      const clientRef = await database.ref(`clients/${user?.id}`);

      const phoneWithoutMask = purgeMask(phone);
      const whatsappWithoutMask = purgeMask(whatsapp);

      const updatedUser = {
        name,
        phone: phoneWithoutMask,
        whatsapp: whatsappWithoutMask,
        contact,
        userAvatar: userAvatarUrl,
        address: {
          zip,
          district,
          street,
          number,
          complement,
          city,
          state,
        },
      };

      await clientRef.update({
        ...updatedUser,
        updatedAt: Date.now(),
      });

      toast.success('Alteração salva com sucesso!', {
        position: 'bottom-center',
      });

      form.resetForm();
      updateUser(updatedUser);
      toggleModalVisibility();
      setFormStep(1);
    } catch (error) {
      const { code } = error as { code: string };
      const message = translateAuthErrors(code);
      toast.error(message);
    }
  }

  async function goToNextStep() {
    if (uploadLoading) return;

    if (form.values.userAvatar && form.values.userAvatar instanceof File)
      await uploadClientAvatar();

    setFormStep(2);
  }

  function goToPreviousStep() {
    setFormStep(1);
  }

  async function handleForgotPassword() {
    if (user?.email) await sendForgotPasswordEmail(user.email);
  }

  return (
    <S.ModalContainer
      isOpen={isEditingProfile}
      onRequestClose={() => handleCloseModal()}
    >
      <S.Content>
        <header>
          <h1>Editar Perfil</h1>
        </header>
        <main>
          <S.Form onSubmit={form.handleSubmit}>
            {formStep === 1 && (
              <>
                <div className="avatar-wrapper">
                  <FileDropzone
                    imagePreview={form.values.userAvatarUrl || user?.userAvatar}
                    allowedFiles={['image/jpg', 'image/jpeg', 'image/png']}
                    buttonDescription="Logo"
                    name="userAvatar"
                    setFieldValue={form.setFieldValue}
                  />
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editName">Nome</label>
                    <input
                      id="editName"
                      type="text"
                      placeholder="Nome"
                      {...form.getFieldProps('name')}
                    />
                    {form.touched.name && form.errors.name && (
                      <span className="input-error">{form.errors.name}</span>
                    )}
                  </div>
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editEmail">E-mail</label>
                    <input
                      id="editEmail"
                      type="text"
                      placeholder="E-mail"
                      readOnly
                      value={user?.email || ''}
                    />
                  </div>
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editPhone">Telefone</label>
                    <InputMask
                      id="editPhone"
                      type="text"
                      placeholder="(99) 99999-9999"
                      mask="(99) 99999-9999"
                      {...form.getFieldProps('phone')}
                    />
                    {form.touched.phone && form.errors.phone && (
                      <span className="input-error">{form.errors.phone}</span>
                    )}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="editWhatsapp">Whatsapp</label>
                    <InputMask
                      id="editWhatsapp"
                      type="text"
                      placeholder="(99) 99999-9999"
                      mask="(99) 99999-9999"
                      {...form.getFieldProps('whatsapp')}
                    />
                    {form.touched.whatsapp && form.errors.whatsapp && (
                      <span className="input-error">
                        {form.errors.whatsapp}
                      </span>
                    )}
                  </div>
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editContact">E-mail de Contato</label>
                    <input
                      id="editContact"
                      type="email"
                      placeholder="Email de Contato"
                      {...form.getFieldProps('contact')}
                    />
                    {form.touched.contact && form.errors.contact && (
                      <span className="input-error">{form.errors.contact}</span>
                    )}
                  </div>
                </div>
                <p>
                  <button type="button" onClick={handleForgotPassword}>
                    Redefinir Senha
                  </button>
                </p>
              </>
            )}
            {formStep === 2 && (
              <>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editZip">CEP</label>
                    <input
                      id="editZip"
                      type="text"
                      placeholder="CEP"
                      {...form.getFieldProps('zip')}
                    />
                    {form.touched.zip && form.errors.zip && (
                      <span className="input-error">{form.errors.zip}</span>
                    )}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="editStreet">Logradouro</label>
                    <input
                      id="editStreet"
                      type="text"
                      placeholder="Rua"
                      {...form.getFieldProps('street')}
                    />
                    {form.touched.street && form.errors.street && (
                      <span className="input-error">{form.errors.street}</span>
                    )}
                  </div>
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editDistrict">Bairro</label>
                    <input
                      id="editDistrict"
                      type="text"
                      placeholder="Bairro"
                      {...form.getFieldProps('district')}
                    />
                    {form.touched.district && form.errors.district && (
                      <span className="input-error">
                        {form.errors.district}
                      </span>
                    )}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="editNumber">Número</label>
                    <input
                      id="editNumber"
                      type="text"
                      placeholder="Número"
                      {...form.getFieldProps('number')}
                    />
                    {form.touched.number && form.errors.number && (
                      <span className="input-error">{form.errors.number}</span>
                    )}
                  </div>
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editComplement">Complemento</label>
                    <input
                      id="editComplement"
                      type="text"
                      placeholder="Complemento"
                      {...form.getFieldProps('complement')}
                    />
                    {form.touched.complement && form.errors.complement && (
                      <span className="input-error">
                        {form.errors.complement}
                      </span>
                    )}
                  </div>
                </div>
                <div className="client-info">
                  <div className="input-wrapper">
                    <label htmlFor="editCity">Cidade</label>
                    <input
                      id="editCity"
                      type="text"
                      placeholder="Cidade"
                      {...form.getFieldProps('city')}
                    />
                    {form.touched.city && form.errors.city && (
                      <span className="input-error">{form.errors.city}</span>
                    )}
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="editState">Estado</label>
                    <input
                      id="editState"
                      type="text"
                      placeholder="Estado"
                      {...form.getFieldProps('state')}
                    />
                    {form.touched.state && form.errors.state && (
                      <span className="input-error">{form.errors.state}</span>
                    )}
                  </div>
                </div>
              </>
            )}

            <footer>
              <Button
                type="button"
                isCancelButton
                onClick={() => handleCloseModal()}
              >
                Cancelar
              </Button>
              {formStep === 1 && (
                <Button type="button" onClick={goToNextStep}>
                  {uploadLoading ? (
                    <ImSpinner className="icon-spin" />
                  ) : (
                    'Avançar'
                  )}
                </Button>
              )}
              {formStep === 2 && (
                <>
                  <Button
                    type="button"
                    isCancelButton
                    onClick={goToPreviousStep}
                  >
                    Voltar
                  </Button>
                  <Button type="submit" disabled={form.isSubmitting}>
                    {!form.isSubmitting ? (
                      'Salvar'
                    ) : (
                      <ImSpinner className="icon-spin" />
                    )}
                  </Button>
                </>
              )}
            </footer>
          </S.Form>
        </main>
      </S.Content>
    </S.ModalContainer>
  );
}
