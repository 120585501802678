import { useContext } from 'react';
import { ProfileContext } from '../contexts/ProfileContext';

type UseProfileModalReturnType = {
  isProfileModalOpened: boolean;
  toggleProfileModalVisibility: () => void;
};

export function useProfileModal(): UseProfileModalReturnType {
  const value = useContext(ProfileContext);

  return value;
}
