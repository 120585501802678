import { useContext } from 'react';
import {
  BenefitsContext,
  BenefitsContextType,
} from '../contexts/BenefitsContext';

export function useBenefits(): BenefitsContextType {
  const value = useContext(BenefitsContext);

  return value;
}
