import { Link, useHistory } from 'react-router-dom';

import { ImSpinner } from 'react-icons/im';
import {
  IoBanOutline,
  IoCheckmarkCircleOutline,
  IoSyncOutline,
  IoTrashOutline,
} from 'react-icons/io5';

import { FiEdit } from 'react-icons/fi';
import { useState } from 'react';
import { database } from '../../../services/firebase';

import { useAuth } from '../../../hooks/useAuth';
import {
  useClients,
  ClientType,
  AccountStatus,
} from '../../../hooks/useClients';
import { Button } from '../../../components/Button';
import { Client } from '../../../components/Client';
import { HeaderInfo } from '../../../components/HeaderInfo';

import * as S from './style';

import logoImg from '../../../assets/images/extended-logo.svg';
import { AdminEditProfileModal } from '../../../components/AdminEditProfileModal';

export function Dashboard(): JSX.Element {
  const [editingClientId, setEditingClientId] = useState('');

  const history = useHistory();
  const { user, signOut } = useAuth();
  const { clients, loading } = useClients();

  const userBadges = clients.reduce(
    (
      accumulator: {
        pendingClients: number;
        activeClients: number;
        inactiveClients: number;
      },
      value: ClientType,
    ) => {
      if (value.accountStatus === AccountStatus.PENDING) {
        return {
          ...accumulator,
          pendingClients: accumulator.pendingClients + 1,
        };
      }
      if (value.accountStatus === AccountStatus.APPROVED) {
        return {
          ...accumulator,
          activeClients: accumulator.activeClients + 1,
        };
      }
      if (value.accountStatus === AccountStatus.REJECTED) {
        return {
          ...accumulator,
          inactiveClients: accumulator.inactiveClients + 1,
        };
      }
      return accumulator;
    },
    { pendingClients: 0, activeClients: 0, inactiveClients: 0 },
  );

  async function handleSignOut() {
    await signOut();

    history.push('/');
  }

  async function approveClient(clientId: string) {
    if (loading) return;

    const clientRef = await database.ref(`clients/${clientId}`);

    await clientRef.update({
      accountStatus: 'approved',
    });
  }

  async function rejectClient(clientId: string) {
    if (loading) return;

    const clientRef = await database.ref(`clients/${clientId}`);

    await clientRef.update({
      accountStatus: 'rejected',
    });
  }

  function toggleModalVisibility(clientId?: string) {
    if (clientId) setEditingClientId(clientId);
    else setEditingClientId('');
  }

  return (
    <S.Page>
      <S.Header>
        <div className="content">
          <Link to="/dashboard">
            <img src={logoImg} alt="Socius" />
          </Link>
          <div>
            {user ? <HeaderInfo /> : <ImSpinner className="icon-spin" />}
            <Button type="button" isOutlined onClick={handleSignOut}>
              Sair
            </Button>
          </div>
        </div>
      </S.Header>
      <S.Main>
        <div className="title-container">
          <div className="list-title">
            <h1>Lista de Clientes</h1>
            {userBadges.pendingClients > 0 && (
              <span className="pending">
                {userBadges.pendingClients} pendente
                {userBadges.pendingClients > 1 && 's'}
              </span>
            )}
            {userBadges.activeClients > 0 && (
              <span>
                {userBadges.activeClients} ativo
                {userBadges.activeClients > 1 && 's'}
              </span>
            )}
            {userBadges.inactiveClients > 0 && (
              <span className="inactive">
                {userBadges.inactiveClients} inativo
                {userBadges.inactiveClients > 1 && 's'}
              </span>
            )}
          </div>
          <Button
            type="button"
            onClick={() => history.push('/admin/check-users')}
          >
            Verificar usuários
          </Button>
        </div>

        <div className="clients-list">
          {clients.map(client => {
            return (
              <Client
                key={client.id}
                name={client.name}
                email={client.email}
                isHighlighted={client.accountStatus === 'pending'}
                isInactive={client.accountStatus === 'rejected'}
              >
                {client.accountStatus === 'pending' && (
                  <>
                    <button
                      type="button"
                      className="approve-button"
                      onClick={() => approveClient(client.id)}
                    >
                      {!loading ? (
                        <IoCheckmarkCircleOutline />
                      ) : (
                        <ImSpinner className="icon-spin" />
                      )}
                    </button>
                    <button
                      type="button"
                      className="reject-button"
                      onClick={() => rejectClient(client.id)}
                    >
                      {!loading ? (
                        <IoBanOutline />
                      ) : (
                        <ImSpinner className="icon-spin" />
                      )}
                    </button>
                  </>
                )}
                {client.accountStatus === 'approved' && (
                  <>
                    <button
                      type="button"
                      className="edit-button"
                      onClick={() => toggleModalVisibility(client.id)}
                    >
                      {!loading ? (
                        <FiEdit />
                      ) : (
                        <ImSpinner className="icon-spin" />
                      )}
                    </button>
                    <button
                      type="button"
                      className="reject-button"
                      onClick={() => rejectClient(client.id)}
                    >
                      <IoTrashOutline />
                    </button>
                  </>
                )}
                {client.accountStatus === 'rejected' && (
                  <button
                    type="button"
                    className="approve-button"
                    onClick={() => approveClient(client.id)}
                  >
                    {!loading ? (
                      <IoSyncOutline />
                    ) : (
                      <ImSpinner className="icon-spin" />
                    )}
                  </button>
                )}
              </Client>
            );
          })}
        </div>
        <AdminEditProfileModal
          editingClientId={editingClientId}
          toggleModalVisibility={toggleModalVisibility}
          clients={clients}
          loading={loading}
        />
      </S.Main>
    </S.Page>
  );
}
