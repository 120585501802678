import { useProfileModal } from '../../hooks/useProfileModal';
import { Button } from '../Button';
import * as S from './style';

type CompleteProfileModalProps = {
  isModalOpened: boolean;
  toggleModalVisibility: () => void;
};

export function CompleteProfileModal({
  isModalOpened,
  toggleModalVisibility,
}: CompleteProfileModalProps): JSX.Element {
  const { toggleProfileModalVisibility } = useProfileModal();
  function handleCloseModal() {
    toggleModalVisibility();
  }

  function handleCompleteProfile() {
    toggleModalVisibility();
    toggleProfileModalVisibility();
  }

  return (
    <S.ModalContainer
      isOpen={isModalOpened}
      onRequestClose={() => handleCloseModal()}
    >
      <S.Content>
        <header>
          <h1>Complete seu Perfil</h1>
        </header>
        <main>
          <p>
            Percebemos que ainda faltam algumas informações para serem
            preenchidas no seu perfil. Recomendamos que você preencha todas as
            informações, melhorando a experiência para os seus clientes.
          </p>
          <div className="buttons-container">
            <Button isOutlined onClick={handleCloseModal}>
              Agora não
            </Button>
            <Button onClick={handleCompleteProfile}>
              Preencher Meu Perfil
            </Button>
          </div>
        </main>
      </S.Content>
    </S.ModalContainer>
  );
}
