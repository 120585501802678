import React from 'react';
import { ImSpinner } from 'react-icons/im';

import * as S from './style';

export function Loading(): JSX.Element {
  return (
    <S.Container>
      <ImSpinner className="icon-spin" />
    </S.Container>
  );
}
