import styled from 'styled-components';

type ClientProps = {
  isHighlighted?: boolean;
  isInactive?: boolean;
};

export const User = styled.div<ClientProps>`
  background: #fefefe;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  padding: 24px;
  display: flex;

  &:not(:first-child) {
    margin-top: 8px;
  }

  .user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    strong {
      font-weight: bold;
      font-size: 16px;
    }

    p {
      font-size: 14px;
      margin-left: 8px;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > div {
      display: flex;
      gap: 16px;
    }

    button {
      display: flex;
      border: 0;
      cursor: pointer;

      transition: filter 0.2s;

      svg {
        font-size: 24px;
      }

      &.approve-button:hover,
      &.edit-button:hover {
        svg {
          color: #835afd;
        }
      }

      &.reject-button:hover {
        svg {
          color: #ea4335;
        }
      }
    }
  }
`;
