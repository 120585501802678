import { createContext, ReactNode, useEffect, useState } from 'react';
import { isBefore } from 'date-fns';
import { useAuth } from '../hooks/useAuth';
import { database } from '../services/firebase';

export type BenefitType = {
  id: string;
  title: string;
  description: string;
  featured_image?: string;
  createdAt: Date;
  updatedAt?: Date | number | null;
  notificationSentAt?: Date | number | null;
};

type FirebaseBenefits = Record<string, BenefitType>;

export type BenefitsContextType = {
  loading: boolean;
  setLoading: (state: boolean) => void;
  benefits: BenefitType[];
  setBenefits: (benefits: BenefitType[]) => void;
};

type BenefitsContextProviderProps = {
  children: ReactNode;
};

export const BenefitsContext = createContext({} as BenefitsContextType);

export function BenefitsContextProvider({
  children,
}: BenefitsContextProviderProps): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [benefits, setBenefits] = useState<BenefitType[]>([]);

  const { user } = useAuth();

  function sortBenefits(unsortedBenefits: BenefitType[]): BenefitType[] {
    const sortedClients = unsortedBenefits.sort(
      (a: BenefitType, b: BenefitType) => {
        if (isBefore(a.createdAt, b.createdAt)) return 1;
        if (isBefore(b.createdAt, a.createdAt)) return -1;

        return 0;
      },
    );

    return sortedClients;
  }

  useEffect(() => {
    const benefitsRef = database.ref(`clients/${user?.id}/benefits`);

    benefitsRef.once('value', benefit => {
      const databaseBenefit: FirebaseBenefits = benefit.val();

      if (!databaseBenefit) {
        setLoading(false);
        return;
      }

      const parsedBenefits = Object.entries(databaseBenefit).map(
        ([key, value]) => {
          return {
            id: key,
            title: value.title,
            description: value.description,
            featured_image: value.featured_image,
            createdAt: value.createdAt,
            updatedAt: value.updatedAt || null,
            notificationSentAt: value.notificationSentAt || null,
          };
        },
      );

      const sortedUsers = sortBenefits([...parsedBenefits]);

      setBenefits(sortedUsers);
      setLoading(false);
    });

    return () => {
      benefitsRef.off('value');
    };
  }, [user?.id]);

  // useEffect(() => {
  //   const benefitsRef = database.ref(`clients/${user?.id}/benefits`);

  //   benefitsRef.on('child_added', firebaseUser => {
  //     const parsedBenefit = {
  //       id: firebaseUser.key,
  //       ...firebaseUser.val(),
  //     };

  //     if (
  //       !benefits.find(stateBenefit => stateBenefit.id === firebaseUser.key)
  //     ) {
  //       setBenefits(previousState => [parsedBenefit, ...previousState]);
  //     }
  //   });

  //   return () => {
  //     benefitsRef.off('child_added');
  //   };
  // }, [benefits, user?.id]);

  // useEffect(() => {
  //   const benefitsRef = database.ref(`clients/${user?.id}/benefits`);

  //   benefitsRef.on('child_changed', firebaseUser => {
  //     const benefitKey = firebaseUser.key;

  //     const updatedClient = {
  //       id: benefitKey,
  //       ...firebaseUser.val(),
  //     };

  //     const updatedBenefits = benefits.map(stateBenefit =>
  //       stateBenefit.id === benefitKey ? updatedClient : stateBenefit,
  //     );

  //     setBenefits(updatedBenefits);
  //   });

  //   return () => {
  //     benefitsRef.off('child_changed');
  //   };
  // }, [benefits, user?.id]);

  // useEffect(() => {
  //   const benefitsRef = database.ref(`clients/${user?.id}/benefits`);

  //   benefitsRef.on('child_removed', firebaseUser => {
  //     const benefitKey = firebaseUser.key;

  //     const updatedBenefits = benefits.filter(
  //       stateBenefit => stateBenefit.id !== benefitKey,
  //     );

  //     setBenefits(updatedBenefits);
  //   });

  //   return () => {
  //     benefitsRef.off('child_removed');
  //   };
  // }, [benefits, user?.id]);

  return (
    <BenefitsContext.Provider
      value={{
        loading,
        setLoading,
        benefits,
        setBenefits,
      }}
    >
      {children}
    </BenefitsContext.Provider>
  );
}
